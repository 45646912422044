import { makeCustomUGRequest, makeRequest } from "../../components/utils/APIsUtils/httpsUtils";
import config from "../../config";
import { currentRegionMapping } from "../../customGroupMarketMapping";

export const SET_IS_CREATE_USER_GROUP = 'SET_IS_CREATE_USER_GROUP';
export const SET_IS_CUSTOM_USER_GROUP = 'SET_IS_CUSTOM_USER_GROUP';
export const SET_IS_EDIT_USER_GROUP = 'SET_IS_EDIT_USER_GROUP';
export const SET_EDIT_USER_GROUP_DATA = 'SET_EDIT_USER_GROUP_DATA';
export const SET_CUSTOM_USER_GROUP_LIST = 'SET_CUSTOM_USER_GROUP_LIST';

const currentRegion = config?.currentRegion

export const setIsCreateUserGroup = (options) => {
  return {
    type: SET_IS_CREATE_USER_GROUP,
    payload: options,
  };
};

export const setIsEditUserGroup = (options) => {
  return {
      type: SET_IS_EDIT_USER_GROUP,
      payload: options,
  };
};

export const setIsCustomUserGroup = (options) => {
  return {
    type: SET_IS_CUSTOM_USER_GROUP,
    payload: options,
  };
};

export const setEditUserGroupData = (options) => {
  return {
    type: SET_EDIT_USER_GROUP_DATA,
    payload: options,
  };
};

export const setCustomUserGroupList = (options) => {
  return {
    type: SET_CUSTOM_USER_GROUP_LIST,
    payload: options,
  };
};

export const fetchCustomUserGroupList = () => {

  return async (dispatch) => {
    try {
            const response = await makeCustomUGRequest({method: 'get', endpoint : `/user-groups?sourceSystem=ACADEMY&adminGroup=${currentRegionMapping[currentRegion]}&includeGroupDetails=true`});

      let userGroups = response?.data?.userGroups || [];

      if (userGroups.length === 0) {
        dispatch(setCustomUserGroupList([]));
        return;
      }

      const fetchGroupDetails = async (id) => {
        try {
          const groupResponse = await makeRequest({
            method: 'GET',
            endpoint: `/admin/api/v1/audience-groups/details?audGrpIds=${id}`,
          });

          const data = groupResponse?.data || {};
          const {courses = [], learningPaths = []} = data;

          const allArchived =
  (courses.length === 0 && learningPaths.length === 0) ||
  (courses.every((course) => course.courseState === "Archive") &&
   learningPaths.every((lp) => lp.learningPathState === "Archive"));


          return {
            id,
            courseCount: data.courseCount || 0,
            learningPathCount: data.learningPathCount || 0,
            allArchived,
          };
        } catch (error) {
          console.error(`Error fetching details for group ${id}:`, error);
          return {id, courseCount: 0, learningPathCount: 0, allArchived: false}; // Default values
        }
      };

      const groupDetails = await Promise.all(
        userGroups.map((group) => fetchGroupDetails(group.id)),
      );

      const updatedUserGroups = userGroups.map((group) => {
        const details = groupDetails.find((detail) => detail.id === group.id) || {};
        return {
          ...group,
          courseCount: details.courseCount,
          learningPathCount: details.learningPathCount,
          allArchived: details.allArchived,
        };
      });

      dispatch(setCustomUserGroupList(updatedUserGroups));
    } catch (error) {
      console.error('Error fetching user groups:', error);
      dispatch(setCustomUserGroupList([]));
    }
  };
};
