import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/th';  // Import Thai locale
import { USER_TIME_ZONE } from './datePicker';
import config from '../config';

const CURRENT_REGION = config?.currentRegion?.toLowerCase();

// Extend dayjs with the necessary plugins
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

// Helper function to format UTC date in user's local timezone and get timezone abbreviation
export const formatDateInUserTimezone = (utcDate, DATE_FORMAT = 'MM/DD/YYYY') => {
  
  // Get timezone abbreviation
  const timezoneAbbreviation = dayjs().tz(USER_TIME_ZONE).format('z'); // 'z' gives the abbreviation (e.g., IST, PST)

  // Convert UTC date to user's timezone and format it
  const formattedDate = CURRENT_REGION === 'th' ? formatDateInThailandTimezone(utcDate) : dayjs.utc(utcDate).tz(USER_TIME_ZONE).format(`${DATE_FORMAT} hh:mm:ss A`);

  return {
    formattedDate,
    timezoneAbbreviation: '',  // Fix the timezone abbreviation return
  };
};

export const formatDateInThailandTimezone = (utcDate, DATE_FORMAT = 'DD/MM/YYYY hh:mm:ss A') => {
  const utcDateObj = dayjs.utc(utcDate);  // Example UTC date
  
  // Convert UTC to Thailand Time (ICT) by using the timezone plugin
  const thailandTimeObj = utcDateObj.tz('Asia/Bangkok');
  
  // Manually subtract 543 from the year to convert to the Buddhist era
  const buddhistYear = thailandTimeObj.year() + 543;

  // Format the date using the Thai locale and the Buddhist year, including time
  const formattedDate = thailandTimeObj
    .locale('th')
    .year(buddhistYear)  // Adjust the year to the Buddhist year
    .format(DATE_FORMAT);  // Format to '6 ม.ค. 68 10:36:00 PM'

  return formattedDate;
};

export const convertThailandBuddhistDateToUTC = (thaiDate, DATE_FORMAT = 'DD/MM/YYYY') => {
  // Parse the date in Thai format using the Thai locale (Buddhist era)
  const parsedDate = dayjs(thaiDate, DATE_FORMAT, true).locale('th');
  
  // Convert the Buddhist year to the Gregorian year by subtracting 543
  const buddhistYear = parsedDate.year() - 543;

  // Adjust the parsed date to the correct Gregorian year
  const gregorianDate = parsedDate.year(buddhistYear);

  console.log("TEST ", {buddhistYear, gregorianDate, parsedYear: parsedDate.year(), parsedDate, thaiDate})

  // Convert the date to Thailand's timezone (Asia/Bangkok)
  const thailandDateInTimezone = gregorianDate.tz('Asia/Bangkok');

  // Convert the Thailand date to UTC
  const utcDate = thailandDateInTimezone.utc();

  // Return the UTC date in ISO 8601 format
  return utcDate.toISOString();
};

// Function to convert entered date and time into UTC format
export const convertToUTC = (dateString, timeString, DATE_FORMAT = 'MM/DD/YYYY') => {
  try {
    // Combine date and time strings (e.g., "12-1-2024" and "01:30")
    const combinedDateTime = `${dateString} ${timeString}`;

    // Parse the combined date and time in a local format (assuming it's in the local timezone)
    const localDateTime = dayjs(combinedDateTime, `${DATE_FORMAT} hh:mm A`);

    // Convert the local date and time to UTC and return it in ISO 8601 format
    return localDateTime?.utc()?.toISOString(); // or use `.toISOString()` for standard ISO format
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const convertFullDateToUTC = (dateWithTime, DATE_FORMAT = 'MM/DD/YYYY') => {
  
  const parsedDateTime = dayjs(dateWithTime, `${DATE_FORMAT} hh:mm A`);

  return convertToUTC(parsedDateTime.format(DATE_FORMAT), parsedDateTime.format('hh:mm:ss A'), DATE_FORMAT);
}
