import React from "react"
import UserGroupForm from "./UserGroupForm"
import styles from "./EditCustomizedUserGroup.module.scss"

const EditCustomizedUserGroup = ({audienceGroupList, onCancelCb, onDeleteCb, setAudienceDetails, allArchive}) => {
    const isDisabled = allArchive === undefined ? false : !allArchive;
    return <div>
        <UserGroupForm audienceGroupList={audienceGroupList} onCancelCb = {onCancelCb} setAudienceDetails={setAudienceDetails}/>
        <button
        className={`${styles.deleteUserGroup} ${isDisabled ? styles.disabled : ''}`}
        onClick={isDisabled ? undefined : onDeleteCb}
      >
        <span className={`${styles.deleteIcon} ${isDisabled ? styles.disabled : ''}`}></span>
        Delete User Group
      </button>
      {isDisabled && <p className={styles.deleteInfo}>A customized user group cannot be deleted when it is associated with learning content.</p>}
    </div>
}

export default EditCustomizedUserGroup


