import React from "react"
import styles from "./CreateCustomizedUserGroup.module.scss"
import UserGroupForm from "./UserGroupForm"
import config from "../../../config";
const {currentRegion, currentEnv} = config;
const CURRENTENV = currentEnv.toLowerCase();
const {apiConfig} = config[currentRegion];
const BASE_CUSTOM_URL = apiConfig[CURRENTENV].baseCustomUGURL;

const CreateCustomizedUserGroup = ({audienceGroupList, onCancelCb, setAudienceDetails}) => {

    return <React.Fragment>
        <div className={styles.drawer_wrapper}>
            <UserGroupForm audienceGroupList={audienceGroupList} onCancelCb={onCancelCb} setAudienceDetails={setAudienceDetails}/>
            <div>
                <a href={`${BASE_CUSTOM_URL}/member-list-template`} className={styles.downloadCsv} download={true}>Download UGM Excel Template</a>
                <p className={styles.downloadCsv_info}>{`This template provides a sample list for creating customized user groups. Please download and edit it if you don't already have a User Group List .csv file ready for upload.`}</p>
            </div>
        </div>
    </React.Fragment>
};

export default CreateCustomizedUserGroup
