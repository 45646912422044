/* eslint-disable no-misleading-character-class */
import React, {createRef, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styles from '../createNewLearningPath/CreateNewLP.module.scss';
import {connect} from 'react-redux';
import config from '../../../config';
import CheckboxNew from '../../shared/checkboxNew';
import SearchListComponent from '../../shared/searchListComponent';
import LoadingShimmerCustom from '../../shared/LoadingShimmerCustom';
const {currentEnv} = config;

const LearningPathDescription = ({
  data,
  handleDataFill,
  setImageData,
  disableImageLib = true,
  thumbnailImage169,
  mainImageBackup,
  setThumbnailImage169,
  setLearningPath,
  uploadImageFromDesktop,
  handleDataFillMultiLingual,
  setNewLPData,
  setNewLPDataCST,
  certificateList,
  uploadNewImagePath,
  setNewLPWizardState,
  setNewThumbnailImageData,
  isLoadingApi = false,
  addedCourseList,
  setAddedCourseList,
  addedCourseListState,
  setNewCourseAddedList,
  setNewAddDeleteUGMList,
  addUserGroupList,
  deleteUserGroupList,
  setNewImageData,
  setMainImageBackup,
  setCroppedImageData,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  selectedSkills,
  pillsDataKeyword,
  categoriesListFull,
  setNewLPDataFull,
  newLPDataFull,
  isDuplicateLearningPathName = false,
  setCourseMode,
}) => {
  const searchListRef = createRef();
  const router = useHistory();
  const [certificateCheckbox, setCertificateCheckbox] = useState(false);
  // const [newLPDataFull, setNewLPDataFull] = useState(null);
  const [newAddedList, setNewLPAddedList] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nameErrorStateEnglish, setNameErrorStateEnglish] = useState(false);
  const [nameErrorStateChinese, setNameErrorStateChinese] = useState(false);
  const [maxCharErrorMessageEnglish, setMaxCharErrorMessageEnglish] = useState(false);
  const [maxCharErrorMessageChinese, setMaxCharErrorMessageChinese] = useState(false);

  const {query = {}} = router.location;
  const [configData, setConfigData] = useState({});

  useEffect(() => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    setConfigData(configData);
  }, []);

  const handleCertificateCheckbox = (name, value) => {
    if (value == true) {
      handleDataFill(name, value);
      setCertificateCheckbox(value);
    } else {
      // delete data.certificateCategory;
      handleDataFill(name, value);
      setCertificateCheckbox(value);
      // setData(data);
    }
  };
  useEffect(() => {
    data?.isCertificateAvailable && setCertificateCheckbox(data?.isCertificateAvailable);
    // setNewLPDataFull(data);

    if (data && (data?.thumbnailId == null || data?.thumbnailId == undefined)) {
      setMainImage(null);
      setNewThumbnailImageData(null);
      setMainImageBackup(null);
      setNewImageData(null);
    } else if (
      data &&
      data?.thumbnailId !== null &&
      data?.thumbnailId !== undefined &&
      mainImageBackup
    ) {
      setMainImage(mainImageBackup);
      setNewThumbnailImageData(data?.thumbnail);
    }
    if (
      data &&
      data?.thumbnail &&
      data?.thumbnail !== undefined &&
      data?.primaryTitle &&
      data?.primaryDescription
    ) {
      setMainImage(data?.thumbnail);
      setNewThumbnailImageData(data?.thumbnail);
    }

    return () => {
      setMainImage(null);
      setNewThumbnailImageData(null);
      // setNewImageData(null);
      // setMainImageBackup(null);
    };
  }, [data]);

  useEffect(() => {
    setNewLPAddedList(addedCourseList);
  }, [addedCourseList]);
  useEffect(() => {
    setIsLoading(isLoadingApi);
  }, [isLoadingApi]);
  useEffect(() => {
    if (data && (data?.thumbnailId == null || data?.thumbnailId == undefined)) {
      setMainImage(null);
    } else if (mainImageBackup) {
      setMainImage(mainImageBackup);
    } else if (data && data?.thumbnail) {
      setMainImage(data?.thumbnail);
    }
  }, [mainImageBackup]);
  return (
    <>
      <div className={[styles.row_wrapper, styles.lp_description_row_wrapper].join(' ')}>
        {/* Info container block */}
        <div className={[styles.info_container, styles.lp_description].join(' ')}>
          <div
            className={[styles.inr_wrapper, styles.margin_zero, 'col-md-12 col-lg-12'].join(' ')}
          >
            <div className={[styles.title_wrapper, styles.margin_zero].join(' ')}>
              <div className={styles.title}>Learning Path Information</div>
              <div className={[styles.txt, styles.margin_zero].join(' ')}>
                Enter Learning Path Information in primary language.
              </div>
              <div className={styles.sub_title}>
                Note: Title, Description and Thumbnail Image are mandatory to save the Learning Path
                in Draft state and to move forward in the Create New Learning Path Wizard.
              </div>
            </div>
          </div>
        </div>
        {/* Info container block */}
        <div className={[styles.info_container].join(' ')}>
          <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                Learning Path Information ({configData?.primaryLanguage})*
              </div>
            </div>
            <div
              className={[
                styles.form_field,
                styles.info_wrapper,
                (maxCharErrorMessageEnglish && newLPDataFull?.primaryTitle?.length > 100) ||
                isDuplicateLearningPathName
                  ? styles.error_wrapper_max_char
                  : ' ',
              ].join(' ')}
            >
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Learning Path Title*</div>
                <div className={styles.tag_text}>Max 100 characters</div>
              </div>
              {isLoading ? (
                <div className={styles.input_wrapper}>
                  <LoadingShimmerCustom className={[styles.input].join(' ')} />
                </div>
              ) : (
                <div className={styles.input_wrapper}>
                  <input
                    type={'text'}
                    placeholder={'Enter Learning Path Title'}
                    className={styles.input}
                    defaultValue={newLPDataFull && newLPDataFull?.primaryTitle?.toString()}
                    value={newLPDataFull && newLPDataFull?.primaryTitle?.toString()}
                    name="primaryTitle"
                    // maxLength="100"
                    onChange={(e) => {
                      e.target.value.length === 0 &&
                        handleDataFill(e?.target?.name?.normalize('NFC'), '');
                      handleDataFill(e.target.name?.normalize('NFC'), e.target.value?.toString());
                      if (e.target.value?.toString()) {
                        setNameErrorStateEnglish(true);
                      } else {
                        setNameErrorStateEnglish(false);
                      }
                      if (e.target.value?.toString()?.length > 100) {
                        setMaxCharErrorMessageEnglish(true);
                      } else {
                        setMaxCharErrorMessageEnglish(false);
                      }
                    }}
                  />
                  {(newLPDataFull &&
                    newLPDataFull?.primaryTitle?.length < 0 &&
                    newLPDataFull?.primaryTitle?.length != 0) ||
                  newLPDataFull?.primaryTitle?.length > 100 ||
                  isDuplicateLearningPathName ? (
                    <div className={styles.error_icon_red}></div>
                  ) : null}
                </div>
              )}

              {newLPDataFull?.primaryTitle?.length > 100 ? (
                <div className={styles.error_message}>Maximum character limit reached</div>
              ) : null}

              {isDuplicateLearningPathName && (
                <div className={styles.error_message}>
                  The learning path name already exists. Please choose a different name.
                </div>
              )}
            </div>

            <div
              className={[
                styles.form_field,
                styles.info_wrapper,
                styles.form_field_margin,
                newLPDataFull?.primaryDescription?.length > 500
                  ? styles.error_wrapper_max_char
                  : ' ',
              ].join(' ')}
            >
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Description*</div>
                <div className={styles.tag_text}>Max 500 characters</div>
              </div>
              {isLoading ? (
                <div className={styles.input_wrapper}>
                  <LoadingShimmerCustom className={[styles.textarea].join(' ')} />
                </div>
              ) : (
                <div className={styles.input_wrapper}>
                  <textarea
                    type={'text'}
                    name="primaryDescription"
                    // maxLength="500"
                    placeholder={'Enter Learning Path Description'}
                    defaultValue={newLPDataFull && newLPDataFull?.primaryDescription}
                    value={newLPDataFull && newLPDataFull?.primaryDescription}
                    className={styles.textarea}
                    onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                    // onBlur={() => callCreateCourseAPI()}
                  />
                  {(newLPDataFull?.newLPDataFull?.length < 0 &&
                    newLPDataFull?.newLPDataFull?.length != 0) ||
                  newLPDataFull?.primaryDescription?.length > 500 ? (
                    <div className={styles.error_icon_red}></div>
                  ) : null}
                </div>
              )}

              {newLPDataFull?.primaryDescription?.length > 500 ? (
                <div className={styles.error_message}>Maximum character limit reached</div>
              ) : null}
            </div>

            <div
              className={[
                styles.form_field,
                styles.info_wrapper,
                newLPDataFull?.primarySpeaker?.length > 100 ? styles.error_wrapper_max_char : ' ',
              ].join(' ')}
            >
              <div className={styles.label_wrapper}>
                <div className={[styles.title_wrapper, styles.margin_zero].join(' ')}>
                  <div className={`${styles.title} m-0`}>
                    Learning Path Author/Speaker Name ({configData?.primaryLanguage})
                  </div>
                </div>
                <div className={[styles.tag_text, styles.tag_text_margin].join(' ')}>
                  Max 100 characters
                </div>
              </div>

              <div className={styles.title_wrapper}>
                <div className={styles.txt}>
                  Enter the Author or a Speaker Name for this Learning Path. This information is
                  visible to the Front End ABO besides the title of ‘Taught by’.
                </div>
              </div>

              <div
                className={[
                  styles.form_field,
                  styles.info_wrapper,
                  newLPDataFull?.primarySpeaker?.length > 100 ? styles.error_wrapper_max_char : ' ',
                ].join(' ')}
              >
                {isLoading ? (
                  <div className={styles.input_wrapper}>
                    <LoadingShimmerCustom className={[styles.input].join(' ')} />
                  </div>
                ) : (
                  <div className={styles.input_wrapper}>
                    <input
                      type={'text'}
                      maxLength="101"
                      name="primarySpeaker"
                      placeholder={'Enter Learning Path Author/Speaker Name'}
                      defaultValue={
                        (newLPDataFull && newLPDataFull?.author) || newLPDataFull?.primarySpeaker
                      }
                      value={
                        (newLPDataFull && newLPDataFull?.author) || newLPDataFull?.primarySpeaker
                      }
                      className={styles.input}
                      onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                    />
                    {(newLPDataFull?.primarySpeaker?.length < 0 &&
                      newLPDataFull?.primarySpeaker?.length != 0) ||
                    newLPDataFull?.primarySpeaker?.length > 100 ? (
                      <div className={styles.error_icon_red}></div>
                    ) : null}
                  </div>
                )}

                {newLPDataFull?.primarySpeaker?.length > 100 ? (
                  <div className={styles.error_message}>Maximum character limit reached</div>
                ) : null}
              </div>
            </div>

            <div
              className={[
                styles.form_field,
                styles.info_wrapper,
                newLPDataFull?.contentCode?.length > 60 ? styles.error_wrapper_max_char : ' ',
              ].join(' ')}
            >
              <div className={styles.label_wrapper}>
                <div className={[styles.title_wrapper, styles.margin_zero].join(' ')}>
                  <div className={`${styles.title} m-0`}>
                    Learning Path Code
                  </div>
                </div>
                <div className={[styles.tag_text, styles.tag_text_margin].join(' ')}>
                  Max 60 characters
                </div>
              </div>

              <div
                className={[
                  styles.form_field,
                  styles.info_wrapper,
                  newLPDataFull?.contentCode?.length > 60 ? styles.error_wrapper_max_char : ' ',
                ].join(' ')}
              >
                {isLoading ? (
                  <div className={styles.input_wrapper}>
                    <LoadingShimmerCustom className={[styles.input].join(' ')} />
                  </div>
                ) : (
                  <div className={styles.input_wrapper}>
                    <input
                      type={'text'}
                      maxLength="61"
                      name="contentCode"
                      placeholder={'Enter Learning Path Order Code'}
                      defaultValue={
                        (newLPDataFull && newLPDataFull?.contentCode) || newLPDataFull?.contentCode
                      }
                      value={
                        (newLPDataFull && newLPDataFull?.contentCode) || newLPDataFull?.contentCode
                      }
                      className={styles.input}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[a-zA-Z0-9._\-\s]*$/;
                        if (regex.test(value)) {
                          handleDataFill(e.target.name, value);
                        }
                      }}
                    />
                    {(newLPDataFull?.contentCode?.length < 0 &&
                      newLPDataFull?.contentCode?.length != 0) ||
                      newLPDataFull?.contentCode?.length > 60 ? (
                      <div className={styles.error_icon_red}></div>
                    ) : null}
                  </div>
                )}

                {newLPDataFull?.contentCode?.length > 60 ? (
                  <div className={styles.error_message}>Maximum character limit reached</div>
                ) : null}
              </div>
            </div>
          </div>

          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(' ')}
          >
            <div className={styles.title_wrapper}>
              <div className={styles.title}>Achievements</div>
              <div className={`${styles.txt} ${styles.achievement_txt}`}>
                Add a certificate for completion of the Learning Path. The ABO will get an
                automatically generated certificate with the Learning Path Name, their Name, and
                Date.
                <div className={styles.italic_txt}>
                  Note: Certificates addition to a Learning Path is only possible during creation of
                  a Learning Path. It cannot be added or removed later.
                </div>
              </div>
            </div>
            <div className={styles.certificate_wrapper}>
              <div className={styles.title}>Certificate</div>

              <div className={styles.checkbox_container}>
                <CheckboxNew
                  isChecked={newLPDataFull?.isCertificateAvailable}
                  handleCheckClick={() => {
                    newLPDataFull && newLPDataFull?.isCertificateAvailable
                      ? handleCertificateCheckbox('isCertificateAvailable', Boolean(false))
                      : handleCertificateCheckbox('isCertificateAvailable', Boolean(true));
                  }}
                  isDisabled={
                    newLPDataFull &&
                    (newLPDataFull.state == 'Live' || newLPDataFull.state == 'Offline')
                      ? true
                      : false
                  }
                />
                <div className={styles.checkbox_note}>
                  ABOs receive a certificate for completing this Learning Path.
                </div>
              </div>
            </div>
            {certificateCheckbox && (
              <div className={styles.certificate_wrapper_list}>
                <div className={styles.title_disable}>
                  Certificate Category (Available in a future update)
                </div>

                <div
                  className={[styles.checkbox_container, styles.checkbox_container_disable].join(
                    '',
                  )}
                  ref={searchListRef}
                >
                  <SearchListComponent
                    list={certificateList}
                    varient="dropdown"
                    pillsLimit={null}
                    handlePillList={(name, id) => {
                      handleDataFill('certificateCategory', id);
                    }}
                    placeHolderText={'Select Certificate Category'}
                    defaultInputData={''}
                    reference={searchListRef}
                    disabledInput={true}
                    setBtn2SaveAndExitDisable={false}
                    isDisabled={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Info container 2nd block */}
        <div className={[styles.info_container].join(' ')}>
          <div
            className={[styles.inr_wrapper, styles.thumbnail_wrapper, 'col-md-12 col-lg-6'].join(
              ' ',
            )}
          >
            <div className={styles.title_wrapper}>
              <div className={styles.title}>Thumbnail Image*</div>
              <div className={styles.txt}>
                Add a Thumbnail Image to this Learning Path by choosing an Image from the Image
                Library or by choosing an Image from your local device to add a New Image.
              </div>
            </div>
            {!mainImage && !isLoading ? (
              <div className={[styles.pre_browse_wrapper, styles.uploadig_state, ''].join(' ')}>
                <div className={styles.browse_img_txt_wrapper}>
                  <div className={styles.browse_img}></div>
                </div>

                <input
                  type={'file'}
                  accept=".jpg,.jpeg"
                  onChange={(event) => {
                    setCourseMode('');
                    setLearningPath('FROM_LEARNING_PATH');
                    setNewLPData(newLPDataFull);
                    setNewLPDataCST({
                      skills: selectedSkills,
                      keywords: pillsDataKeyword,
                      categories: categoriesListFull,
                    });
                    setNewCourseAddedList(newAddedList);
                    // setNewAddDeleteUGMList({
                    //   addUserGroupList: addUserGroupList,
                    //   deleteUserGroupList: deleteUserGroupList,
                    // });
                    setNewLPWizardState({WizardOpen: true});
                    setNewThumbnailImageData(mainImage);
                    setImageData({
                      imageName: null,
                      imageDescription: null,
                      pillsData: null,
                    });

                    const file = event?.target?.files?.length ? event?.target?.files[0] : null;
                    uploadImageFromDesktop(event, file);
                  }}
                  hidden={true}
                  multiple={false}
                  id={'chooseImage'}
                  disabled={disableImageLib}
                />
                <label htmlFor="chooseImage">
                  <div className={[styles.browse_text, styles.secondary_text].join(' ')}>
                    Choose Image from Local Device
                  </div>
                </label>

                <div className={styles.text}>or</div>

                <div className={styles.browse_img_txt_wrapper}>
                  <div
                    className={styles.browse_text}
                    onClick={() => {
                      setImageData({
                        imageName: null,
                        imageDescription: null,
                        pillsData: null,
                      });
                      if (!disableImageLib) {
                        setThumbnailImage169(null);
                        setNewLPData(newLPDataFull);
                        setNewLPDataCST({
                          skills: selectedSkills,
                          keywords: pillsDataKeyword,
                          categories: categoriesListFull,
                        });
                        setNewCourseAddedList(newAddedList);
                        // setNewAddDeleteUGMList({
                        //   addUserGroupList: addUserGroupList,
                        //   deleteUserGroupList: deleteUserGroupList,
                        // });
                        setNewLPWizardState({WizardOpen: true});
                        setCourseMode('');
                        setLearningPath('FROM_LEARNING_PATH');
                        router.push('/Contentmanagement');
                        setNewThumbnailImageData(mainImage);
                      }
                    }}
                  >
                    Choose Image from Image Library{' '}
                  </div>
                </div>
              </div>
            ) : mainImage && !isLoading ? (
              <div className={[styles.thumbnail_inner_wrapper, ''].join(' ')}>
                <div className={[styles.top_section, ' '].join(' ')}>
                  <div className={[styles.btn_wrapper_uaf, styles.margin_zero].join(' ')}>
                    <div
                      className={styles.txt}
                      onClick={() => {
                        setImageData({
                          imageName: null,
                          imageDescription: null,
                          pillsData: null,
                        });
                        if (!disableImageLib) {
                          setThumbnailImage169(null);
                          setNewCourseAddedList(newAddedList);
                          // setNewAddDeleteUGMList({
                          //   addUserGroupList: addUserGroupList,
                          //   deleteUserGroupList: deleteUserGroupList,
                          // });
                          setNewLPDataCST({
                            skills: selectedSkills,
                            keywords: pillsDataKeyword,
                            categories: categoriesListFull,
                          });
                          setNewLPData(newLPDataFull);
                          setNewLPWizardState({WizardOpen: true});
                          setNewThumbnailImageData(mainImage);
                          setCourseMode('');
                          setLearningPath('FROM_LEARNING_PATH');
                          router.push('/Contentmanagement');

                          //setOpenImageLib();
                          // setNewCourseData({
                          //   newLPDataFull: newLPDataFull,
                          //   adaptFile: fileData,
                          //   skills: pillsDataSkills,
                          //   keyword: pillsDataKeyword,
                          //   newCourseID: newCourseID,
                          //   categoryData: categoryData,
                          //   pillsAddAudienceGroup: pillsAddAudienceGroup,
                          // });
                          //setNewAdaptFile(fileData);
                          // setNewCourseWizardState({
                          //   courseWizardOpen: true,
                          //   courseWizardStateOpen: 'main',
                          //   section: '',
                          // });
                        }
                      }}
                    >
                      Choose From Image Library
                    </div>
                    <div className={styles.book_icon}></div>
                  </div>
                  <div
                    className={styles.insturction_text_wrapper}
                    onClick={(event) => {
                      setImageData({
                        imageName: null,
                        imageDescription: null,
                        pillsData: null,
                      });
                      if (!disableImageLib) {
                        setNewLPData(newLPDataFull);
                        setNewLPDataCST({
                          skills: selectedSkills,
                          keywords: pillsDataKeyword,
                          categories: categoriesListFull,
                        });
                        setNewCourseAddedList(newAddedList);
                        // setNewAddDeleteUGMList({
                        //   addUserGroupList: addUserGroupList,
                        //   deleteUserGroupList: deleteUserGroupList,
                        // });
                        setNewLPWizardState({WizardOpen: true});
                        setNewThumbnailImageData(mainImage);
                        setCourseMode('');
                        setLearningPath('FROM_LEARNING_PATH');
                        uploadNewImagePath();

                        //setNewAdaptFile(fileData);
                        setImageData({
                          imageName: null,
                          imageDescription: null,
                          pillsData: null,
                        });
                      }
                      setNewLPData(newLPDataFull);
                      setNewLPDataCST({
                        skills: selectedSkills,
                        keywords: pillsDataKeyword,
                        categories: categoriesListFull,
                      });
                      setNewCourseAddedList(newAddedList);
                      // setNewAddDeleteUGMList({
                      //   addUserGroupList: addUserGroupList,
                      //   deleteUserGroupList: deleteUserGroupList,
                      // });
                      setNewLPWizardState({WizardOpen: true});
                      setNewThumbnailImageData(mainImage);
                      setCourseMode('');
                      setLearningPath('FROM_LEARNING_PATH');

                      setImageData({
                        imageName: null,
                        imageDescription: null,
                        pillsData: null,
                      });
                      setNewThumbnailImageData(mainImageBackup);
                      setNewImageData(null);
                      //setMainImageBackup(null);
                      setCroppedImageData(null);
                      setCroppedImage_16_9(null);
                      setCroppedImage_1_1(null);
                      setSelectedImage_16_9_Backup(null);
                      setSelectedImage_1_1_Backup(null);

                      const file = event?.target?.files?.length ? event?.target?.files[0] : null;
                      uploadImageFromDesktop(event, file);
                    }}
                  >
                    <div className={styles.info_text}>Upload New Image</div>
                    <div className={styles.left_arrow_icon}></div>
                  </div>
                </div>
                <div className={[styles.img_wrapper, ' '].join(' ')}>
                  <div className={styles.img}>
                    <img
                      // src={ImageDetail?.image_16_9_url ? ImageDetail.image_16_9_url : ""}
                      src={
                        //newThumbnailId?.length > 0 &&
                        thumbnailImage169?.img
                          ? thumbnailImage169?.img
                          : mainImageBackup && data?.learningPathId
                          ? mainImageBackup
                          : mainImage
                      }
                      alt="Thambnail"
                      className={styles.selected_img}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <LoadingShimmerCustom className={[styles.pre_browse_wrapper, ''].join(' ')} />
            )}
            <div className={styles.tag_text}> 3MB max | .jpg, jpeg</div>
          </div>
        </div>
      </div>
    </>
  );
};


LearningPathDescription.props = {};

export default React.memo(connect(null, null)(LearningPathDescription));
