import React, {useEffect, useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import IncorrectUrlComponent from '../components/shared/pageNotFoundComponent/index';
import {  useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const PageNotFound = (props) => {
  const history = useHistory();
  const { authState } = useOktaAuth();
  
  useEffect(()=>{
    if(authState?.isAuthenticated) {
      console.log("DEBUG PAGE NOT FOUND /login")
      history.push('/login');
    }
  },[]);

  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          {/* <SideNavigationComponent pageName="CONTENT-MANAGEMENT" {...props} /> */}
          <IncorrectUrlComponent />
        </main>
      </div>
    </div>
  );
};

PageNotFound.propTypes = {};

export default PageNotFound;
