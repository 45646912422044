import React, { useEffect, useRef, useState } from 'react'
import Papa from "papaparse"
import styles from './UserGroupForm.module.scss'
import InputField from '../Input'
import { useDispatch, useSelector } from 'react-redux'
import { setNotificationData } from '../../../redux/actions/uiActions'
import { validateCSVData } from './utils'
import Notification from '../../shared/notification'
import { makeCustomUGRequest } from '../../utils/APIsUtils/httpsUtils'
import { fetchCustomUserGroupList, setEditUserGroupData, setIsCreateUserGroup, setIsCustomUserGroup, setIsEditUserGroup } from '../../../redux/actions/userGroupManagementAction'
import config from '../../../config'
import { currentRegionMapping } from '../../../customGroupMarketMapping'

const currentRegion = config?.currentRegion
const createCustomUserGroup = async (userGroupNameData, userGroupDescriptionData) => {
    const userGroupResponse = await makeCustomUGRequest({
        endpoint : '/user-groups', 
        method : 'post', 
        body : {
            name : userGroupNameData.value,
            description : userGroupDescriptionData.value,
            adminGroup : currentRegionMapping[currentRegion],
            sourceSystem : 'ACADEMY'
        }
    })
    return userGroupResponse
}

const updateCustomUserGroup = async (userGroupNameData, userGroupDescriptionData, id) => {
    const userGroupResponse = await makeCustomUGRequest({
        endpoint : `/user-groups/${id}`, 
        method : 'put', 
        body : {
            name : userGroupNameData.value,
            description : userGroupDescriptionData.value,
        }
    })
    return userGroupResponse
}

const doesUserGroupNameExists = (customGroups, standardGroups, groupName, isEdit, editUserGroupData) => {
    const lowerCaseName = groupName?.toLowerCase();
    const isDuplicate = (list, key) => list?.some(item => item[key]?.toLowerCase() === lowerCaseName);

    if (isEdit && lowerCaseName === editUserGroupData?.userGroupName?.toLowerCase()) {
        return false;
    }

    return isDuplicate(customGroups, 'name') || isDuplicate(standardGroups, 'audienceGrpName');
};

const uploadCsvFile = async (id, formData) => {
    const uploadedCsv = await makeCustomUGRequest({
        endpoint : `/user-groups/${id}/members`,
        method : 'put',
        body: formData,
        contentType: 'multipart/form-data'
    })
    return uploadedCsv
}

const UserGroupForm = ({audienceGroupList, onCancelCb, setAudienceDetails}) => {

    const inputRef = useRef()

    const {notificationData} = useSelector(state=>state.uiState)
    const {isEditUserGroup, editUserGroupData, customUserGroupList} = useSelector(state=>state.userGroupManagement)

    const [userGroupNameData, setUserGroupNameData] = useState({
        value : editUserGroupData?.userGroupName || '',
        error : '',
        disabled : ''
    })
    const [userGroupDescriptionData, setUserGroupDescriptionData] = useState({
        value : editUserGroupData?.userGroupDescription || '',
        error : '',
        disabled : ''
    })
    const [isCsvFileValid, setIsCsvFileValid] = useState(false)
    const [file, setFile] = useState()
    const [isFileChanged, setIsFileChanged] = useState(false)

    const [isSaveUserGroupButtonDisabled, setIsSaveUserGroupButtonDisabled] = useState(true);

    const dispatch = useDispatch()

    useEffect(()=>{
        if(isEditUserGroup){
            setFile(true)
            setIsCsvFileValid(true)
        }
    },[])

    useEffect(()=>{
        const hasDataChanged = editUserGroupData?.userGroupName !== userGroupNameData?.value || editUserGroupData?.userGroupDescription !== userGroupDescriptionData?.value;

        if(doesUserGroupNameExists(customUserGroupList, audienceGroupList, userGroupNameData.value, isEditUserGroup, editUserGroupData)){
            setUserGroupNameData(prev => ({...prev, error : 'Customized user group name cannot be the same as an existing standard or customized user group.'}))
            setIsSaveUserGroupButtonDisabled(true)
        }
        else if(userGroupNameData.value?.trim() && userGroupDescriptionData.value?.trim() && !userGroupNameData.error && !userGroupDescriptionData.error && file && isCsvFileValid && hasDataChanged ){
            setIsSaveUserGroupButtonDisabled(false)
        }
        else if(isFileChanged && userGroupNameData.value?.trim() && userGroupDescriptionData.value?.trim()){
            setIsSaveUserGroupButtonDisabled(false)
        }
        else {
            setIsSaveUserGroupButtonDisabled(true)
        }
    },[userGroupNameData.value, userGroupDescriptionData.value, userGroupNameData.error, userGroupDescriptionData.error, file, isCsvFileValid])

    
    const handleFileChange = (event) => {
        setIsCsvFileValid(false)
        setFile(null)
        setIsFileChanged(true)

        const file = event.target.files[0];

        if (file.type !== "text/csv") {
            dispatch(setNotificationData({
                show: true,
                type: 'WARNING',
                title: 'File Format Error',
                description: `Group List must be CSV UTF-8 (Comma delimited) (*.csv).`,
            }));
            return;
        }

        Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
            const {status, type = '', title = '', description = ''} = validateCSVData(results.data);
            if (!status) {
                dispatch(setNotificationData({
                    show: true,
                    type,
                    title,
                    description,
                }));
                return;
            }

            setFile(file)
            setIsCsvFileValid(true)

            dispatch(setNotificationData({
                show: true,
                type: 'SUCCESS',
                title: `User Group List ${(isCsvFileValid && file) || isEditUserGroup ? 'Replaced' : 'Uploaded'} Successfully`,
                description: `Uploaded list data saved to system`,
            }));

        },
        error: (error) => {
            dispatch(setNotificationData({
                show: true,
                type: 'WARNING',
                title: 'Error parsing CSV file',
                description: `Error parsing CSV file: ${error.message}`,
            }));
        },
        });
        event.target.value=null;
    };

    const handleSubmit = async () => {

        const formData = new FormData();
        formData.append("file", file);

        if(!isEditUserGroup){
            const userGroupResponse = await createCustomUserGroup(userGroupNameData,userGroupDescriptionData)
    
            if(userGroupResponse !== 'ERR_NETWORK' && userGroupResponse?.data?.id) {
                const uploadedCsv = await uploadCsvFile(userGroupResponse.data.id, formData)
                if(uploadedCsv.status === 200){
                    dispatch(setNotificationData({
                        show: true,
                        type: 'SUCCESS',
                        title: 'New User Group Created Successfully',
                        description: `User Group ${userGroupResponse.data?.name} added to system`,
                    }));
                    setAudienceDetails({
                        audienceGroupId: userGroupResponse.data?.id,
                        audienceGrpName: userGroupResponse.data?.name,
                        audienceGrpDesc: userGroupResponse.data?.description,
                        courses: [],
                        learningPaths: []
                    })
                    dispatch(setEditUserGroupData({
                        userGroupId: userGroupResponse.data?.id,
                        userGroupName: userGroupResponse.data?.name,
                        userGroupDescription: userGroupResponse.data?.description, 
                    }))
                    dispatch(fetchCustomUserGroupList())
                    dispatch(setIsCreateUserGroup(false))
                    dispatch(setIsCustomUserGroup(true))
                    // onCancelCb()
                } 
            }
        } else {
            const userGroupResponse = await updateCustomUserGroup(userGroupNameData,userGroupDescriptionData, editUserGroupData?.userGroupId)
            if(userGroupResponse !== 'ERR_NETWORK' && isFileChanged && file && isCsvFileValid) {
                const uploadedCsv = await uploadCsvFile(editUserGroupData?.userGroupId, formData)
                if(uploadedCsv.status === 200){
                    dispatch(setNotificationData({
                        show: true,
                        type: 'SUCCESS',
                        title: 'User Group Edited Successfully',
                        description: `Changes to ${userGroupNameData.value} saved to system`,
                    }));
                    dispatch(fetchCustomUserGroupList())
                    dispatch(setIsCreateUserGroup(false))
                    dispatch(setIsEditUserGroup(false))
                    dispatch(setIsCustomUserGroup(true))
                    onCancelCb()
                } 
            } else if(userGroupResponse !== 'ERR_NETWORK') {
                dispatch(setNotificationData({
                    show: true,
                    type: 'SUCCESS',
                    title: 'User Group Edited Successfully',
                    description: `Changes to ${userGroupNameData.value} saved to system`,
                }));
                dispatch(fetchCustomUserGroupList())
                dispatch(setIsEditUserGroup(false))
                dispatch(setIsCustomUserGroup(true))
                onCancelCb()
            }
        }
    }

    return <div className={styles.form_wrapper}>
        <InputField
            label={'User Group Name *'}
            inputInfo={'Max 50 characters'}
            inputValue={userGroupNameData.value}
            onChangeCb={(e)=>{
                if(e.target.value?.length <= 50){
                    setUserGroupNameData({...userGroupNameData, value : e.target.value, error : ''})
                }
                else{
                    setUserGroupNameData({...userGroupNameData, error : 'Maximum characters reached'})
                }
            }}
            isError={!!userGroupNameData?.error}
            errorMessage={userGroupNameData?.error}
        />
        <InputField 
            label={'Description *'}
            inputInfo={'Max 500 characters'}
            inputValue={userGroupDescriptionData.value}
            onChangeCb={(e)=>{
                if(e.target.value?.length <= 500) {
                    setUserGroupDescriptionData({...userGroupDescriptionData, value : e.target.value, error : null})
                }
                else{
                    setUserGroupDescriptionData({...userGroupDescriptionData, value : e.target.value, error : 'Maximum characters reached'})
                }
            }}
        />
        <div className={styles.fileUpload_container}>
            <div className={styles.fileUpload_wrapper}>
                <p style={{marginBottom : '8px'}}>User Group List *</p>
                <p className={styles.replaceCsv} onClick={ () => inputRef.current.click() } >{(isCsvFileValid && file) || isEditUserGroup ? 'Replace Current User Group List' : 'Upload User Group List'}</p>
                {/* <p className={styles.replaceCsv} onClick={ () => inputRef.current.click() } >Replace Current User Group List</p> */}
                {/* <div onClick={ () => inputRef.current.click() } className={styles.fileUpload}/> */}
            </div>
        </div>
        <input ref={inputRef} accept='.csv' style={{display : 'none'}} type='file' onChange={handleFileChange}/>
        <div className={styles.button_wrapper}>
            <button className={`${styles.btn} ${styles.cancelBtn}`} onClick={()=>onCancelCb()}>Cancel</button>
            <button type='submit' className={`${styles.btn} ${isSaveUserGroupButtonDisabled ? styles.disabled : styles.saveBtn}`} onClick={handleSubmit}>{isEditUserGroup?'Save Changes':`Save New User Group`}</button>
        </div>
        {notificationData?.show && <Notification />}
    </div>
}

export default UserGroupForm