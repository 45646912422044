import React, {useState, useEffect, useRef} from 'react';
import LoadingShimmerCustom from '../LoadingShimmerCustom';
import styles from './Header.module.scss';

const handleNextAndSaveChangesButton = (
  handleNextButton,
  isBtn2Disable,
  setX,
  setY,
  handleDisableClick,
  actionName,
  handleSaveChanges,
  saveChangesProps,
) => {
  if (handleNextButton && !isBtn2Disable) {
    handleNextButton();
    setX(false);
    setY(true);
  } else if (handleDisableClick && isBtn2Disable) {
    handleDisableClick();
  }

  // subHeaderCategoriesClick(true);
  if (actionName) {
    handleSaveChanges && !isBtn2Disable && handleSaveChanges({actionName, saveChangesProps});
  } else {
    handleSaveChanges && !isBtn2Disable && handleSaveChanges(saveChangesProps);
  }
};

const isButtonOne = (
  isBtn1,
  isBtn1Disable,
  handleButton,
  btnName1,
  handleCloseButtonProps,
  isLoadingHeader,
) =>
  isBtn1 && !isLoadingHeader ? (
    <button
      className={
        isBtn1Disable ? [styles.btn, styles.disabled, styles.disabled_btn_1].join(' ') : styles.btn
      }
      onClick={() => handleButton(handleCloseButtonProps)}
    >
      {btnName1 ? btnName1 : ''}
    </button>
  ) : isLoadingHeader ? (
    <LoadingShimmerCustom className={styles.btn} />
  ) : null;

const isButtonTwo = (
  isBtn2,
  isBtn2Disable,
  handleNextButton,
  handleDisableClick,
  actionName,
  handleSaveChanges,
  btnName2,
  setX,
  setY,
  saveChangesProps,
  isLoadingHeader,
) =>
  isBtn2 && !isLoadingHeader ? (
    <button
      className={
        isBtn2Disable
          ? [styles.btn, styles.btn_lg, styles.disabled, styles.disabled_btn_2].join(' ')
          : [styles.btn, styles.btn_lg, styles.active].join(' ')
      }
      onClick={() => {
        handleNextAndSaveChangesButton(
          handleNextButton,
          isBtn2Disable,
          setX,
          setY,
          handleDisableClick,
          actionName,
          handleSaveChanges,
          saveChangesProps,
        );
      }}
    >
      {btnName2 ? btnName2 : ''}
    </button>
  ) : isLoadingHeader ? (
    <LoadingShimmerCustom className={[styles.btn, styles.btn_lg, styles.active].join(' ')} />
  ) : null;

const IsBackButton = (
  handleBackButton,
  isLoadingHeader,
) =>
  !isLoadingHeader ? (
    <button
      className={styles.btn}
      onClick={() => handleBackButton()}
    >
      Back
    </button>
  ) : isLoadingHeader ? (
    <LoadingShimmerCustom className={styles.btn} />
  ) : null;

const IsProgressBarCheck = ({
  x,
  subHeaderClick,
  setX,
  setY,
  y,
  subHeaderCategoriesClick,
  list,
  setSelectedSubHeader,
  selectedSubHeader,
  selectedSubHeaderNew,
  widthHeader,
}) => {
  const myRef = useRef(null);

  const executeScroll = (id) => {
    // myRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
    var element = document.getElementById(id);
    element?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
  };
  useEffect(() => {
    selectedSubHeader && executeScroll(selectedSubHeader);
  }, [selectedSubHeader]);
  return (
    <div className={styles.progress_container}>
      <div className={styles.progress_bar_wrapper}>
        {list?.map((data, index) => {
          return (
            <div
              className={
                selectedSubHeader == data.title
                  ? [styles.course_section, styles.selected].join(' ')
                  : [styles.course_section].join(' ')
              }
              ref={myRef}
              onClick={() => {
                if (data.subHeaderClick) {
                  setX(false);
                  setY(true);
                  subHeaderCategoriesClick(true);
                  setSelectedSubHeader(data.title);
                  selectedSubHeaderNew(data.title);
                  //executeScroll(index);
                }
              }}
              id={data.title}
              key={index}
            >
              <div className={styles.icon_wrapper}>
                <div className={styles[data.className]}></div>
              </div>
              <div className={styles.txt}>{data.title}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.progress_bar}>
        <div
          style={{
            width: `${widthHeader}%`,
            height: '6px',
            backgroundColor: '#38539a',
          }}
        ></div>
      </div>
    </div>
  );
};

const HeaderComponent = ({
  headerName,
  headerXSName,
  isHeaderXS,
  btnName1,
  btnName2,
  isBtn1,
  isBtn2,
  isBackBtn,
  isProgressBar,
  handleButton,
  handleNextButton,
  subHeaderCategoriesClick,
  isBtn1Disable,
  isBtn2Disable,
  headerButtons,
  header_btn_adapt_option,
  handleSaveChanges,
  actionName,
  handleDisableClick,
  isSideNavigationPresent = true,
  saveChangesProps,
  handleCloseButtonProps,
  plainTitleWithBold = false,
  planiText = '',
  isPreviewAdaptFile = false,
  list = [],
  selectedSubHeaderNew,
  subheader = '',
  isLoading = false,
  handleBackButton
}) => {
  const [width, setWidth] = useState(0);
  const [isLoadingHeader, setIsLoadingHeader] = useState(false);
  // const [height, setHeight] = useState(0);
  const [subHeaderClick, setSubHeaderClick] = useState(false);
  const [selectedSubHeader, setSelectedSubHeader] = useState(list[0]?.title ? list[0]?.title : '');
  const [x, setX] = useState(true);
  const [y, setY] = useState(false);
  // const [marginScrollOn, setMarginScrollOn] = useState(false);
  // const subHeaderOne = useRef(null);
  // const subHeaderTwo = useRef(null);
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    isProgressBar &&
      selectedSubHeaderNew &&
      selectedSubHeaderNew(list[0]?.title ? list[0]?.title : '');
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    //setHeight(window.innerHeight);
  };
  const [widthHeader, setWidthHeader] = useState(0);
  useEffect(() => {
    let index = list.findIndex((data) => {
      return data.title == selectedSubHeader;
    });
    if (index !== null && index !== -1) {
      setWidthHeader((100 / list?.length) * (index + 1));
    }
  }, [list, selectedSubHeader]);
  useEffect(() => {
    isProgressBar && setSubHeaderClick(headerButtons);
  }, [headerButtons]);
  useEffect(() => {
    isProgressBar && setSelectedSubHeader(subheader);
  }, [subheader]);
  useEffect(() => {
    setIsLoadingHeader(isLoading);
  }, [isLoading]);

  // const scrollSubHeaderOne = () => {
  //   setMarginScrollOn(true)
  //   subHeaderOne.current?.scrollIntoView();

  // };
  // const scrollSubHeaderTwo = () => {
  //   setMarginScrollOn(false)
  //   subHeaderTwo.current?.scrollIntoView();

  // };

  return (
    <React.Fragment>
      <div
        className={`${styles.header_main_wrapper} ${
          isSideNavigationPresent ? '' : styles.without_sidenav
        }`}
      >
        <div className={styles.inner_wrapper}>
          <div
            className={[
              styles.header_wrapper,
              isProgressBar ? styles.lp_header_wrapper : '',
              isHeaderXS ? styles.heading_xs_active : '',
            ].join(' ')}
          >
          {isBackBtn &&
            (<div
              className={[
                styles.back_btn_main_wrapper,
              ].join(' ')}
            >
              <div className={[styles.back_btn_wrapper].join(' ')}>
              {IsBackButton(
                handleBackButton,
                isLoadingHeader
              )}
              </div>
            </div>)
          }

            {isHeaderXS ? (
              <div className={styles.heading_xs}>{headerXSName ? headerXSName : ''}</div>
            ) : null}

            {plainTitleWithBold === true ? (
              isHeaderXS && !plainTitleWithBold && width > 315 && width < 768 ? null : (
                <div className={[styles.heading].join(' ')}>
                  <span className={styles.planiText}>{planiText}</span>
                  {headerName || ''}
                </div>
              )
            ) : isHeaderXS && width > 315 && width < 768 ? null : (
              <div className={[styles.heading].join(' ')}>
                {headerName || ''}
              </div>
            )}

            {isBtn1 || isBtn2 ? (
              <div
                className={[
                  styles.btn_main_wrappper,
                  styles.btn_xs,
                  header_btn_adapt_option ? styles.header_btn_adapt_option : '',
                ].join(' ')}
              >
                <div className={[styles.btn_wrapper].join(' ')}>
                  {/* {isBtn1 ? isButtonOne(isBtn1,isBtn1Disable,handleButton,btnName1,btnName2) :null}
                  {isBtn2 ? isButtonTwo(isBtn2,isBtn2Disable,handleNextButton,handleDisableClick,actionName,handleSaveChanges,btnName2,setX,setY) : null} */}
                  {isButtonOne(
                    isBtn1,
                    isBtn1Disable,
                    handleButton,
                    btnName1,
                    handleCloseButtonProps,
                    isLoadingHeader,
                  )}
                  {isButtonTwo(
                    isBtn2,
                    isBtn2Disable,
                    handleNextButton,
                    handleDisableClick,
                    actionName,
                    handleSaveChanges,
                    btnName2,
                    setX,
                    setY,
                    saveChangesProps,
                    isLoadingHeader,
                  )}
                </div>
              </div>
            ) : null}
          </div>
          {/* Progress Bar Start */}
          {isProgressBar ? (
            <IsProgressBarCheck
              x={x}
              subHeaderClick={subHeaderClick}
              setX={setX}
              setY={setY}
              y={y}
              subHeaderCategoriesClick={subHeaderCategoriesClick}
              list={list}
              setSelectedSubHeader={setSelectedSubHeader}
              selectedSubHeader={selectedSubHeader}
              selectedSubHeaderNew={selectedSubHeaderNew}
              widthHeader={widthHeader}
            />
          ) : null}

          {/* Progress Bar End */}
        </div>
      </div>
    </React.Fragment>
  );
};

HeaderComponent.propTypes = {};

export default HeaderComponent;
