import React, {createRef, useEffect, useRef, useState} from 'react';
import LoadingShimmerCustom from '../../shared/LoadingShimmerCustom';
import styles from '../createNewLearningPath/CreateNewLP.module.scss';
const ReviewAndPublish = ({
  isTitle = false,
  isDescription = false,
  isThumbnail = false,
  isSpeaker = false,
  //isAllCoursesLive = false,
  handleGoToDescriptionPage,
  handleGoToSequencingPage,
  addedCourseList = [],
  data = null,
  openArchivedModal,
  isLoadingApi,
}) => {

  const LPState = data?.state ? data?.state : '';
  const invalidCourse = addedCourseList.filter((ele) => ele.courseState !== 'Live');
  const [openArchived, setOpenArchived] = useState(false);
  return (
    <div
      className={[
        styles.inner_wrapper,
        styles.category_wrapper,
        styles.review_publish_wrapper,
        '',
      ].join(' ')}
    >
      <div className={styles.row_wrapper}>
        <div className={[styles.info_container, styles.category_inner_wrapper].join(' ')}>
          <div
            className={[styles.inr_wrapper, styles.margin_pad_x, 'col-md-12 col-lg-12'].join(' ')}
          >
            <div className={styles.title_wrapper}>
              <div className={styles.title}>Mandatory fields</div>
              <div className={styles.txt}>
                If all the mandatory fields are not fixed, you will not be able to publish the
                Learning Path. Please fix these fields before publishing.
              </div>
            </div>

            <div className={[styles.review_publish_lp].join(' ')}>
              {/* <div className={styles.title}>Learning Path Description</div> */}
              <div className={styles.title}>Translation</div>
              <div className={styles.card_wrapper}>
                {(isLoadingApi) ? (
                  <div
                    className={[
                      styles.card_gutters,
                      isTitle ? styles.success_review_publish : styles.error_review_publish,
                      'col-md-12 col-lg-4',
                    ].join(' ')}
                  >
                    <LoadingShimmerCustom className={[styles.card_shimmer].join(' ')} />
                  </div>
                ) : (
                  <div
                    className={[
                      styles.card_gutters,
                      isTitle ? styles.success_review_publish : styles.error_review_publish,
                      'col-md-12 col-lg-4',
                    ].join(' ')}
                  >
                    <div className={styles.card}>
                      <div className={styles.status_icon} />
                      <div className={styles.details_wrapper}>
                        <div className={styles.title}>
                          {isTitle ? 'Course Translation Added' : 'Recommended: Add [Language] Translation'}
                        </div>
                        <div className={styles.sub_title}>
                          {isTitle
                            ? 
                            ''
                            // 'Vietnamese Title on Learning Path Description page is filled.'
                            : 
                            // 'Vietnamese Title on Learning Path Description page is missing.'
                            'The Course “Course Name Here” has [Language] as a language but the learning path does not. It is recommended to ‘Go to Page’ to add translation.'
                            }
                        </div>
                      </div>
                      {isTitle ? null : (
                        <div
                          className={styles.go_page}
                          onClick={() => {
                            handleGoToDescriptionPage();
                          }}
                        >
                          Go To Page
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.review_publish_lp}>
              <div className={styles.title}>Course Sequencing</div>
              <div className={styles.card_wrapper}>
                {isLoadingApi ? (
                  <div
                    className={[
                      styles.card_gutters,
                      ((invalidCourse?.length === 0 && (LPState == 'Draft' || LPState == '')) ||
                        LPState == 'Live' ||
                        LPState == 'Offline') &&
                      addedCourseList.length >= 2
                        ? styles.success_review_publish
                        : styles.error_review_publish,
                      'col-md-12 col-lg-12',
                    ].join(' ')}
                  >
                    <LoadingShimmerCustom className={[styles.card_shimmer].join(' ')} />
                  </div>
                ) : (
                  <div
                    className={[
                      styles.card_gutters,
                      ((invalidCourse?.length === 0 && (LPState == 'Draft' || LPState == '')) ||
                        LPState == 'Live' ||
                        LPState == 'Offline') &&
                      addedCourseList.length >= 2
                        ? styles.success_review_publish
                        : styles.error_review_publish,
                      // 'col-md-12 col-lg-12',
                      `col-md-12 col-lg-${((invalidCourse?.length === 0 && (LPState == 'Draft' || LPState == '')) ||
                      LPState == 'Live' ||
                      LPState == 'Offline') &&
                      addedCourseList.length >= 2
                    ? 3 : 12}`,
                    ].join(' ')}
                  >
                    <div className={styles.card}>
                      <div className={styles.status_icon} />
                      <div className={styles.details_wrapper}>
                        <div className={styles.title}>
                          {' '}
                          {((invalidCourse?.length === 0 &&
                            (LPState == 'Draft' || LPState == '')) ||
                            LPState == 'Live' ||
                            LPState == 'Offline') &&
                          addedCourseList.length >= 2
                            ? 'All Courses are Live'
                            : 'All Courses are not Live'}
                        </div>
                        <div className={styles.sub_title}>
                          {((invalidCourse?.length === 0 &&
                            (LPState == 'Draft' || LPState == '')) ||
                            LPState == 'Live' ||
                            LPState == 'Offline') &&
                          addedCourseList.length >= 2
                            ? 
                            ''
                            // 'This Learning Path has more than 2 courses. All courses in this Learning Path are Live. If you change the status of these courses to offline while editing the course, then they shall appear offline to all the User Groups. The ABO will not receive Learning Path certificate if any particular course is offline if they haven’t started or their course is In-Progress.'
                            : 'Add a minimum of 2 courses in the Learning Path. Some courses in this Learning Path are not Live. Change the status of these courses by editing the course or remove them from this Learning Path. You can only publish this Learning Path when all the courses are Live.'}
                        </div>
                      </div>
                      {((invalidCourse?.length === 0 && (LPState == 'Draft' || LPState == '')) ||
                        LPState == 'Live' ||
                        LPState == 'Offline') &&
                      addedCourseList.length >= 2 ? null : (
                        <div
                          className={styles.go_page}
                          onClick={() => {
                            handleGoToSequencingPage();
                          }}
                        >
                          Go To Page
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {LPState == 'Live' || LPState == 'Offline' ? (
              <div
                className={[styles.review_publish_lp, styles.decommission_section, ''].join(' ')}
              >
                <div
                  className={styles.title_wrapper}
                  onClick={() => setOpenArchived(!openArchived)}
                >
                  <div className={styles.danger_icon}></div>
                  <div className={styles.title}>Decommission Learning Path</div>
                  <div
                    className={[
                      styles.arrow,
                      openArchived ? styles.up_arrow : styles.down_arrow,
                    ].join(' ')}
                  ></div>
                </div>
                <div className={[styles.card_wrapper, openArchived ? '' : 'hide'].join(' ')}>
                  <div className={[styles.card_gutters, 'col-md-12 col-lg-12'].join(' ')}>
                    <div className={styles.description}>
                      This will permanently remove a Learning Path from the Academy App. Users who
                      have taken the Learning Path will still see the archived version in their
                      Completed Learning Path section but may not re-take it. No user can start the
                      Learning Path once archived. This action cannot be undone.{' '}
                    </div>
                    <div
                      className={[styles.btn, styles.archive].join(' ')}
                      onClick={() => openArchivedModal(true)}
                    >
                      <div className={styles.delete_icon}></div>
                      <div className={styles.text}>Archive This Learning Path</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewAndPublish.propTypes = {};

export default ReviewAndPublish;
