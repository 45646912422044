import React from 'react';
import LoadingShimmer from '../../shared/LoadingShimmer';
import StatusLabel from '../../shared/StatusLabel';
import styles from '../Portal.module.scss';
import ViewAllComponent from '../ViewAllComponent';
import { IS_THAILAND } from '../../../utils/datePicker';
import { formatDateInThailandTimezone } from '../../../utils/date';

const LearningPathSectionView = (props) => {
  const {
    learning,
    searchLearningPath,
    predictedSearchLP,
    Hint,
    setNotificationData,
    setSearch,
    setCourses,
    courses,
    setLearning,
    setAudience,
    handleSearch,
    makeRequest,
    FilterComponent,
    sortData,
    EmptyScreenComponent,
    setSelectedNavigationOption,
    router,
    showEmptyTemplateFor,
    setSearchLearningPath,
    learningPathAPIResponse,
    setPredictedSearchLP,
    handleSearchInputLP,
    searchUserGroup,
    audience,
    filterDataLP,
    setFilterDataLP,
    setFilterDataTempLP,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyStateLP,

    filterDropdownLP,
    redirectLearningPath,
    headingName,
    handleFilterApplyNewLP,
    isSearchFilterEmptyStateLP,
    dateIsoToNormalDate,
    lpFilter,
    isLoading,
    setFilterDropdown,
    setFilterDropdownLP,
    setFilterDropdownUGM,
    setIsLoading,
    height,
  } = props;
  return (
    <div className={['col-sm-12 col-md-12 col-xl-5 col-lg-12 pull-left'].join(' ')}>
      <div
        style={{height: (100 / 100) * (height - 95) + 'px'}}
        className={[styles.card_wrapper, styles.learning_section].join(' ')}
      >
        <div className={styles.header_wrapper}>
          <div className={styles.txt}>Learning Paths</div>
          <div className={styles.action_wrapper}>
            {learning ? (
              <div
                className={[
                  styles.search_wrapper,
                  searchLearningPath.length > 0 ? styles.outline : null,
                ].join(' ')}
              >
                <Hint options={predictedSearchLP} allowTabFill={true}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchLearningPath}
                    onChange={(event) => {
                      handleSearchInputLP({
                        event,
                        setSearchLearningPath,
                        learningPathAPIResponse,
                        setPredictedSearchLP,
                      });
                    }}
                    onKeyPress={(event) =>
                      handleSearchInputLP({
                        event,
                        setSearchLearningPath,
                        learningPathAPIResponse,
                        setPredictedSearchLP,
                      })
                    }
                    className={styles.search_input}
                    onKeyDown={(e) => {
                      if (
                        e.keyCode == 13 &&
                        searchUserGroup?.length > 0 &&
                        searchUserGroup?.length < 3
                      ) {
                        setNotificationData({
                          show: false,
                          type: 'WARNING',
                          title: 'Insufficient Search Input',
                          description: 'To search for any User group, enter 3 or more characters.',
                        });
                      }
                    }}
                  />
                </Hint>
                <div className={styles.icon_wrapper}>
                  {/* here we added cross icon in search and it should be conditional once user                                                                                                                                                                                       start typing */}
                  <div
                    className={[
                      styles.search,
                      searchLearningPath.length > 0 ? styles.cross_icon : null,
                    ].join(' ')}
                    onClick={() => {
                      handleSearch({
                        value: 'learning',
                        setSearch,
                        setCourses,
                        courses,
                        setLearning,
                        setAudience,
                        learning,
                        audience,
                      });
                      setSearchLearningPath('');
                      setPredictedSearchLP([]);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.icon_wrapper}>
                <div
                  className={styles.search_white}
                  onClick={() =>
                    handleSearch({
                      value: 'learning',
                      setSearch,
                      setCourses,
                      courses,
                      setLearning,
                      setAudience,
                      learning,
                      audience,
                    })
                  }
                />
              </div>
            )}
            <div className={styles.filter_main_wrapper} id="divParentOfFilterUGM">
              <div className={[styles.icon_wrapper].join(' ')}>
                <div
                  className={styles.filter}
                  onClick={(e) => {
                    setFilterDropdownLP(!filterDropdownLP);
                    setFilterDropdown(false);
                    setFilterDropdownUGM(false);
                  }}
                />
              </div>
              {filterDropdownLP ? (
                <FilterComponent
                  isPills={true}
                  headingName={headingName}
                  isLandingPage={true}
                  lpFilter={lpFilter}
                  filterData={filterDataLP}
                  sortData={sortData}
                  handleFilterApply={(params) => {
                    handleFilterApplyNewLP({
                      params,
                      filterDataLP,
                      setFilterDataLP,
                      setFilterDataTempLP,
                      makeRequest,
                      setLearningPathAPIResponse,
                      setLearningPathAPIResponseCopy,
                      setSearchFilterEmptyStateLP,
                      setIsLoading,
                    });
                  }}
                  handleFilterCancle={() => setFilterDropdownLP(false)}
                />
              ) : null}
            </div>
          </div>
        </div>
        {/* Empty error container */}

        {/* <div className={[styles.learning_image_container, ''].join(' ')}>
                    <div className={styles.learning_middle_section}>
                      <div className={styles.learning_image_area}>
                        <div
                          className={[styles.empty_screen_icon, styles.learning_path_icon].join(
                            ' ',
                          )}
                        ></div>
                        <div className={styles.empty_screen_path_detail}>
                          <div className={styles.error_label}>Learning Path Management</div>
                          <div className={styles.error_content}>
                            The ability to group courses into Learning Paths coming later in 2022.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

        <div
          style={{height: (100 / 100) * (height - 135) + 'px'}}
          className={styles.learning_card_wrapper}
        >
          <div
            className={[styles.p_lr_10, styles.learning_card, 'col-xl-12 col-md-6 pull-left'].join(
              ' ',
            )}
          >
            {learningPathAPIResponse?.length > 0 && !isLoading ? (
              learningPathAPIResponse?.map((item, index) => {
                return (
                  <div
                    className={styles.card}
                    key={index}
                    onClick={() => {
                      redirectLearningPath({
                        id: item?.learningPathId,
                        viewType: 'card',
                        setSelectedNavigationOption,
                        router,
                      });
                    }}
                  >
                    <div className={[styles.image_wrapper, styles.empty_state].join(' ')}>
                      {item?.thumbnail ? (
                        <img src={item?.thumbnail} alt={'selected Mob-TAB course'} />
                      ) : (
                        <div className={[styles.img, styles.empty_state_img].join(' ')}></div>
                      )}
                    </div>
                    <div className={styles.details_wrapper}>
                      <div className={styles.top_wrapper}>
                        {/* <StatusLabel item={item} large={false} /> */}
                        <div
                          className={[
                            styles.tbl_data,
                            item?.state === 'Live'
                              ? styles.live_card
                              : item?.state === 'Offline'
                              ? styles.offline_card
                              : item?.state === 'Draft'
                              ? styles.draft_card
                              : item?.state === 'Archive'
                              ? styles.archive
                              : '',
                          ].join(' ')}
                        >
                          <div className={[styles.txt, styles.highlight].join(' ')}>
                            {item?.state}
                          </div>
                        </div>

                        {item?.state?.toLowerCase() !== 'draft' && item?.launchDate && (
                          <div className={styles.block_wrapper}>
                            <div
                              className={[
                                item?.state?.toLowerCase() === 'live'
                                  ? styles.calendar
                                  : item?.state?.toLowerCase() === 'offline'
                                  ? [styles.offline_cal_icon].join(' ')
                                  : [styles.archive_cal_icon].join(' '),
                              ]}
                            />
                            <div className={styles.date}>
                            {IS_THAILAND ? formatDateInThailandTimezone(item?.createdDate, 'DD/MM/YYYY') : dateIsoToNormalDate(item?.createdDate)}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={styles.description}>{item?.title || 'NA'}</div>
                      <div className={styles.bottom_wrapper}>
                        <div className={styles.block_wrapper}>
                          <div className={styles.course_bold} />
                          <div className={styles.date}>{item?.courseCount}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : isSearchFilterEmptyStateLP && !isLoading ? (
              <EmptyScreenComponent small={true} />
            ) : (
              isLoading && <LoadingShimmer count={30} isLearningPath={true} />
            )}

            <ViewAllComponent
              isLoading={isLoading}
              showEmptyTemplateFor={showEmptyTemplateFor}
              list={learningPathAPIResponse}
              SelectedNavigationOptionText={'LEARNING-PATH'}
              pageName="Learning Paths"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

LearningPathSectionView.propTypes = {};

export default LearningPathSectionView;
