import { format, getYear } from "date-fns";
import config from '../config'

const CURRENT_REGION = config?.currentRegion?.toLowerCase();

export const IS_THAILAND = CURRENT_REGION === 'th' ? true : false;
export const USER_TIME_ZONE = CURRENT_REGION === 'th' ? 'Asia/Bangkok' : Intl.DateTimeFormat().resolvedOptions().timeZone;

// Function to convert Gregorian year to Buddhist year
function convertToBuddhistYear(date) {
  const gregorianYear = getYear(date); // Extract the Gregorian year
  return gregorianYear + 543; // Convert to Buddhist year
}

// Formatter for Year Dropdown
export const getDayPickerFormatters = () => {
  if (CURRENT_REGION === 'th') {
    return {
      formatYearDropdown: (date, options) => {
        const buddhistYear = convertToBuddhistYear(date); // Convert the year to Buddhist year
        return format(date, `${buddhistYear}`, options); // Format the date with Buddhist year
      },
    };
  }

  return {};
};