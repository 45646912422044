import React, { useEffect, useRef, useState } from 'react';
import styles from './RoasterFileModal.module.scss';
import DeleteIcon from '../../../styles/images/deleteIcon.svg';
import AdditionIcon from '../../../styles/images/addition_icon_blue.svg';
import CourseIcon from '../../../styles/images/course_icon_white.svg'
import DeleteWhiteIcon from '../../../styles/images/delete-black.svg'
import DatePickerWithTimeSelection from '../datePickerWithTimeSelection';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboList, setAboList, setCoursePassedDropdown, setCourseStatusDropdown, setEditFormCourseScore, setEditFormStartDate, setEditFormStatusDate, setIsRoasterFilterFormDirty, setSelectedAboList } from '../../../redux/actions/roaster/roasterListingActions';
import { setNotificationData } from '../../../redux/actions/uiActions';
import SideDrawer from '../sidedrawer/SideDrawer';
import dayjs from 'dayjs';
import { makeRequest } from '../../utils/APIsUtils/httpsUtils';
import { convertFullDateToUTC, convertToUTC, formatDateInUserTimezone } from '../../../utils/date';
import { IS_THAILAND } from '../../../utils/datePicker';

export const DropDown = (props) => {
  const {
    list = [],
    selectedItem = '',
    setCountrySelected = () => { },
    flexProperty = 0,
    marginLeft = 0,
    dropdownStyles = {},
    disabled = false,
    disabledMessage = 'Dropdown is disabled',
    onChangeCb = () => { },
    placeholder = 'Select',
    label = '',
    inputInfo = ''
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    selectedItem || list?.length > 0 ? list[0] : 'No item selected',
  );
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedValue(selectedItem);
  }, [selectedItem])

  const handleToggleDD = () => {
    if (disabled) {
      dispatch(setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Disabled',
        description: disabledMessage,
      }));

      return;
    }
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    setCountrySelected(value);
    onChangeCb(value);
  };

  return (
    <div
      className={styles.drop_down}
      ref={dropdownRef}
      style={{ flex: flexProperty, marginLeft: marginLeft }}
    >
      {label && <div className={styles.labelContainer}>
        {label && <label><b>{label}</b></label>}
        {inputInfo && <span>{inputInfo}</span>}
      </div>}

      <div className={[styles.selected_arrow_item, disabled ? styles.isDisabled : ''].join(' ')} onClick={handleToggleDD} style={dropdownStyles}>
        {selectedValue?.id ? 
          <div className={styles.selected_item}>{selectedValue?.name}</div> 
          : 
          <div className={`${styles.selected_item_placeholder} ${!disabled ? styles.active : ''}`}>{placeholder}</div>
        }
        <div className={isOpen ? styles.up_arrow_icon : styles.arrow_icon }></div>
      </div>
      {isOpen && (
        <div className={styles.list}>
          {list.length > 0 &&
            list.map((element, index) => (
              <div
                key={index}
                className={
                  selectedValue.name === element.name ? styles.list_item_selected : styles.list_item
                }
                onClick={() => handleOptionClick(element)}
              >
                {element.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const InputField = (props) => {
  const { placeholderValue = '', inputValue = '', marginLeft = 0, flexProperty = 0, disabled = false , label = '', inputInfo = '', onChangeCb = () => {}} = props;
  return (
    <>
      <div className={styles.labelContainer}>
        {label && <label><b>{label}</b></label>}
        {inputInfo && <span>{inputInfo}</span>}
      </div>
      <input
        type="text"
        className={[styles.inputField, disabled ? styles.disabled : styles.active].join(' ')}
        style={{ marginLeft: marginLeft, flex: flexProperty }}
        value={inputValue}
        placeholder={placeholderValue}
        disabled={disabled}
        onChange={onChangeCb}
      />
    </>
  );
};

const FilterApplyButton = (props) => {
  const { flexProperty = 0, marginLeft = 0, btnText = '', disabled = false, onChange = () => { } } = props;
  return (
    <>
      <button
        className={styles.buttonStyle}
        disabled={disabled}
        style={{ flex: flexProperty, marginLeft: marginLeft }}
        onClick={() => onChange()}
      >
        {btnText}
      </button>
    </>
  );
};

const ActionButtonIcon = (props) => {
  const { flexProperty = 0, marginLeft = 0, actionBtnType = '', handleOpenModal = () => { }, isDisabled } = props;
  const backGroundUrl = {
    flexProperty: flexProperty,
    backgroundImage: `url(${actionBtnType})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '24px',
    height: '24px',
  };
  return (
    <>
      <div className={`${styles.actionBtnContainer} ${isDisabled ? styles.disabled : ''}`} onClick={handleOpenModal}>
        <div className={styles.actionBtn} style={backGroundUrl}></div>
      </div>
    </>
  );
};

const ActionBtnResponsive = (props) => {
  const { btnTheme = 'whiteTheme', btnIcon = '', btnText = '' , onClickCb = () => {}} = props;
  const btnIconStyle = {
    backgroundImage: `url(${btnIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '13px',
    height: '14px',
  };
  return (
    <div
      className={[
        btnTheme === 'whiteTheme'
          ? styles.responsiveBtnStyleWhiteThemed
          : styles.responsiveBtnStyleDarkThemed,
        styles.commonBtnStyle
      ].join(' ')}
      onClick={onClickCb}
    >
      <div className={styles.responsiveBtnTxt}>{btnText}</div>
      <div className={styles.responsiveBtnIcon} style={btnIconStyle}></div>
    </div>
  );
};

const isValidInteger = (input) => {
  const regex = /^(0|[1-9][0-9]?|100)$/;
  return regex.test(input);
};

const RoasterFilterModal = (props) => {
  const { handleOpenAddRecordModal, handleOpenDeleteRecordModal,score,setScore,getFetchAboListPayload} = props;

  const { aboListPaginationProps } = useSelector((state) => state?.roasterListing);
  

  const onScoreChangeHandler = (val) => {
    if (val > 100 || val < 0) {
      dispatch(setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Something went wrong',
        description: 'Please enter a value between 0 and 100',
      }));
    }
    else {
      setScore(val)
    }
  }



  const dispatch = useDispatch();
  const [isApplyBtnDisabled, setIsApplyBtnDisabled] = useState(true);
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

  const {
    courseStatusDropdown,
    coursePassedDropdown,
    courseStartDate,
    courseStatusDate,
    courseScore,
    selectedCourseStatus,
    selectedAboList
  } = useSelector((state) => state?.roasterListing);

  const {
    selectedCourse
  } = useSelector((state) => state?.courseManagement);

  const getIsEditLearningRecordFormDirty = ()=>{
    return courseStatusDropdown?.value?.id || courseStartDate?.formattedDate || courseStatusDate?.formattedDate || courseScore?.value || coursePassedDropdown?.id
  }

  useEffect(()=>{
    const isEditLearningRecordFormDirty = getIsEditLearningRecordFormDirty()
    dispatch(setIsRoasterFilterFormDirty(isEditLearningRecordFormDirty))
  },[courseStatusDropdown?.value?.id, courseStartDate?.formattedDate, courseStatusDate?.formattedDate, courseScore?.value, coursePassedDropdown?.id])

  let DATE_FORMAT = useSelector(state => state?.uiState?.remoteConfig?.date_format || 'MM/DD/YYYY');
  DATE_FORMAT = IS_THAILAND ? 'MM/DD/YYYY' : DATE_FORMAT; 

  const parseApiDataForDates = (dates) => {
    console.log('dates: ', dates);
    const parsedDate = dayjs(dates?.formattedDate, `${DATE_FORMAT} h:mm:ss A`);

    // Format startDate as MM-DD-YYYY
    const startDate = parsedDate.format('MM-DD-YYYY');

    // Extract and convert startTime to HH:mm (24-hour format)
    const startTime = parsedDate.format('HH:mm');

    // Return the data in the desired format
    return {
      startDate,
      startTime,
    };
  }

  // console.log('test ',formatDateInUserTimezone("2024-12-05T11:51:29Z"));

  // const parsedDateTime = dayjs("05/12/2024 05:21:29 PM", 'DD/MM/YYYY hh:mm:ss A');
  // console.log('test to UTC ',convertToUTC(parsedDateTime.format('DD/MM/YYYY'), parsedDateTime.format('hh:mm:ss A')));

  // console.log("check ", convertToUTC(parsedDateTime.format('DD/MM/YYYY'), parsedDateTime.format('hh:mm:ss A')) === "2024-12-05T11:51:29Z" )

  const handleFilterApply = async () => {
    
    let updatedEditData = {
      ids: selectedAboList?.map((item) => item?.rowId),
      // registration: selectedAboList?.map((item) => item?.registration),
      // registration: 'abcde',
      entryId: selectedCourse?.courseId,
      startDate: convertFullDateToUTC(courseStartDate?.formattedDate, DATE_FORMAT),
      statusDate: convertFullDateToUTC(courseStatusDate?.formattedDate, DATE_FORMAT),
      score: courseScore?.value || undefined,
      status: courseStatusDropdown?.value?.id?.toLowerCase(),
      // startTime: startDateData?.startTime,
      // statusTime: statusDateData?.startTime,
      courseName: selectedCourse?.primaryTitle,
      passed: coursePassedDropdown?.value?.id === 'Yes' ? true : coursePassedDropdown?.value?.id === 'No' ? false : undefined,
    }

    let endpoint = `/admin/api/v1/learning-records`;
    const editLearningRecord = await makeRequest({
      method: 'PUT',
      body: updatedEditData,
      endpoint,
    });

    if (editLearningRecord !== 'ERR_NETWORK' && editLearningRecord !== 'ERR_BAD_REQUEST' && editLearningRecord) {
      dispatch(setNotificationData({
        show: true,
        type: 'SUCCESS',
        title: 'Record Edit Complete',
        description: `Changes to the selected course ${selectedAboList.length > 0 ? 'record(s)' : 'record'} saved and will be reflected within the system.`,
      }));
    }
    else if((editLearningRecord?.status?.toLowerCase() === 'fail' || editLearningRecord === 'ERR_BAD_REQUEST') && !isValidInteger(courseScore?.value)){
      dispatch(setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Record Edit Failed',
        description: 'Request failed with status code 400',
      }));
    }
    else if(editLearningRecord?.status?.toLowerCase() === 'fail' || editLearningRecord === 'ERR_BAD_REQUEST'){
      dispatch(setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Record Edit Failed',
        description: 'Changes to the selected course record(s) did not save. Check your Internet connection or contact support.',
      }));
    }
    updatedEditData = null;
    dispatch(setIsRoasterFilterFormDirty(false))
    dispatch(setSelectedAboList([]));
    dispatch(fetchAboList({ payload: getFetchAboListPayload(), paginationKeys: aboListPaginationProps?.keys }));
  };

  useEffect(() => {
    if (courseStatusDropdown?.value?.id && courseStartDate?.formattedDate && courseStatusDate?.formattedDate) {
      setIsApplyBtnDisabled(false);
    } else {
      setIsApplyBtnDisabled(true);
    }
  }, [courseStatusDropdown, coursePassedDropdown, courseStartDate, courseStatusDate, courseScore])

  const EditFormForTabs = (
      <div>
        <h2 style={{marginBottom: "24px"}}>Edit Record(s)</h2>

        <DropDown 
          selectedItem={courseStatusDropdown?.value}
          list={courseStatusDropdown?.options} 
          disabled={courseStatusDropdown?.isDisabled} 
          disabledMessage={courseStatusDropdown?.disabledReason}
          placeholder={courseStatusDropdown?.placeholder}
          onChangeCb={(value) => dispatch(setCourseStatusDropdown({value}))}
          flexProperty={2} 
          label="Status"
        />

        <InputField 
          placeholderValue="Score" 
          flexProperty={1} label="Score" 
          inputInfo="Between 0 - 100" 
          inputValue={courseScore?.value || ''} 
          onChangeCb={(e) => dispatch(setEditFormCourseScore({value: e.target.value}))}
          disabled={selectedAboList?.length === 0}
        />

        <DropDown 
          selectedItem={coursePassedDropdown?.value}
          list={coursePassedDropdown?.options}
          disabled={coursePassedDropdown?.isDisabled} 
          disabledMessage={coursePassedDropdown?.disabledReason} 
          onChangeCb={(value) => dispatch(setCoursePassedDropdown({value}))}
          placeholder={coursePassedDropdown?.placeholder}
          flexProperty={2}
          label="Passed"
        />

        <DatePickerWithTimeSelection 
          flexProperty={3} 
          dateType="Start" 
          date={courseStartDate} 
          setDateHanlder={(val) => dispatch(setEditFormStartDate(val))} 
          label="Start Date"
          //disabled={courseStartDate?.isDisabled} 
        />

        <DatePickerWithTimeSelection 
          flexProperty={3}
          dateType="Status"  
          date={courseStatusDate} 
          label="Status Date"
          setDateHanlder={(val) => dispatch(setEditFormStatusDate(val))}  
         //disabled={courseStatusDate?.isDisabled}
        />

        <FilterApplyButton 
          flexProperty={1} 
          btnText="Apply" 
          disabled={isApplyBtnDisabled}
        />

      <button 
        className={[styles.buttonStyle, styles.secondaryBtn].join(' ')}
        onClick={() => setIsSideDrawerOpen(false)}
      >
        Cancel
      </button>
      </div>
    )

  return (
    <>
      <div className={styles.filterContainer}>
        <DropDown
          selectedItem={courseStatusDropdown?.value}
          list={courseStatusDropdown?.options}
          disabled={courseStatusDropdown?.isDisabled}
          disabledMessage={courseStatusDropdown?.disabledReason}
          placeholder={courseStatusDropdown?.placeholder}
          onChangeCb={(value) => {
            dispatch(setCourseStatusDropdown({ value }))}}
          flexProperty={2}
        />

        <DatePickerWithTimeSelection
          flexProperty={3}
          marginLeft={8}
          dateType="Start"
          date={courseStartDate}
          setDateHanlder={(val) => dispatch(setEditFormStartDate(val))}
          disabled={coursePassedDropdown?.isDisabled}
        />

        <DatePickerWithTimeSelection
          flexProperty={3}
          marginLeft={8}
          dateType="Status"
          date={courseStatusDate}
          startCourseDate={courseStartDate?.formattedDate ? courseStartDate : null}
          setDateHanlder={(val) => dispatch(setEditFormStatusDate(val))}
          disabled={coursePassedDropdown?.isDisabled}
        />

        <InputField 
          placeholderValue="Score" 
          marginLeft={8} 
          flexProperty={1} 
          inputValue={courseScore?.value || ''} 
          onChangeCb={(e) => {
              if(!isNaN(e.target.value) && e.target.value>=0 && e.target.value<=100){
                dispatch(setEditFormCourseScore({value: e.target.value}))
              } else {
                dispatch(setNotificationData({
                  show: true,
                  type: 'FAILURE',
                  title: 'Invalid Input',
                  description: `Score should be a number and range must be between 0-100`,
                }));
              }
            }
          }
          disabled={coursePassedDropdown?.isDisabled}
        />

        <DropDown
          selectedItem={coursePassedDropdown?.value}
          list={coursePassedDropdown?.options}
          disabled={coursePassedDropdown?.isDisabled}
          disabledMessage={coursePassedDropdown?.disabledReason}
          onChangeCb={(value) => dispatch(setCoursePassedDropdown({ value }))}
          placeholder={coursePassedDropdown?.placeholder}
          flexProperty={2}
          marginLeft={8}
        />

        <FilterApplyButton
          flexProperty={1}
          marginLeft={8}
          btnText="Apply"
          disabled={isApplyBtnDisabled}
          onChange={handleFilterApply}
        />

        <div className={styles.add_delete_icons}>
          <ActionButtonIcon actionBtnType={DeleteIcon} marginLeft={8} handleOpenModal={handleOpenDeleteRecordModal} isDisabled={selectedAboList?.length ? false : true}/>
          <ActionButtonIcon
            actionBtnType={AdditionIcon}
            marginLeft={8}
            handleOpenModal={handleOpenAddRecordModal}
          />
        </div>
      </div>

      <SideDrawer
        isOpen={isSideDrawerOpen}
        onClose={() => setIsSideDrawerOpen(false)}
        BodyComponent={EditFormForTabs}
      />

      <div className={styles.filterMobile}>
        <ActionBtnResponsive
          btnTheme="darkTheme"
          btnIcon={CourseIcon}
          btnText="Edit Selected Record"
          onClickCb={() => {
            selectedAboList?.length ? setIsSideDrawerOpen(true) : dispatch(setNotificationData({
              show: true,
              type: 'FAILURE',
              title: 'Button is Disabled',
              description: "Please select at least one checkbox to edit the records",
            }));
          }}
        />
        <ActionBtnResponsive 
          btnIcon={DeleteWhiteIcon} 
          btnText="Delete Selected Record"
          onClickCb={() => {
            selectedAboList?.length ? handleOpenDeleteRecordModal() : dispatch(setNotificationData({
              show: true,
              type: 'FAILURE',
              title: 'Button is Disabled',
              description: "Please select at least one checkbox to edit the records",
            }));
          }}
        />
      </div>
    </>
  );
};

export default RoasterFilterModal;
