import {makeRequest} from '../../utils/APIsUtils/httpsUtils';

import {marketEnvConfig} from '../../../marketEnvConfig';
import { timeDifference } from '../commonUtil';

export const fetchCoursesListWithParams = async (props) => {
  const {
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setShowEmptyTemplateFor,
    setSearchFilterEmptyStatecourses,
    setCourseListDefaultFilter,
    setErrorStateTemplate,
    setCourseFullList,
    setCourseListCopy,
    setCourseList,
    setShowViewAllListView,
    setShowViewAllCardView,
    search,
    filter,
    dateIsoToTime,
    dateIsoToDate,
    dateIsoToNormalDate,
    isOnline,
  } = props;
  const configData = marketEnvConfig();
  let endpoint = `/admin/api/v1/courses?locale=${configData?.locale}`;

  if (sortBy) {
    endpoint += `&sortBy=${sortBy.toLowerCase()}&courseState=${bodyVar?.states?.toString()}`;
  }
  // if (courseState){
  //   endpoint  += `&courseState=${courseState}`
  // }
  const dataCourses = await makeRequest({
    method: 'POST',
    endpoint,
  });
  if (dataCourses != 'ERR_NETWORK' && dataCourses) {
    if (dataCourses?.data?.length === 0) {
      setShowEmptyTemplateFor('COURSES');
      setCourseList([]);
    } else {
      let newDataCourses = [];
      newDataCourses = dataCourses?.data;
      let tilesLables = [];
      if (tilesFilterArray !== []) {
        for (const ele of tilesFilterArray) {
          if (ele?.checked) {
            tilesLables.push(ele?.title);
          }
        }
        if (tilesLables?.length > 0) {
          newDataCourses = dataCourses?.data.filter((ele) => {
            return tilesLables.includes(ele?.course_state);
          });
        }
      }
      setCourseListDefaultFilter(newDataCourses);
      setSearchFilterEmptyStatecourses(true);
      setErrorStateTemplate({
        variant: '',
        status: false,
      });
      //setSearchFilterEmptyState(true)
      setCourseFullList(newDataCourses);

      //setCourseListDefaultFilter(dataCourses?.data);
      setCourseList(newDataCourses?.slice(0, 30));
      setCourseListCopy(newDataCourses);
      if (newDataCourses?.length > 30) {
        setShowViewAllCardView(true);
        setShowViewAllListView(true);
      } else {
        setShowViewAllCardView(false);
        setShowViewAllListView(false);
      }
      if (search && search.length && search.length >= 1) {
        setCourseList(
          filter(newDataCourses, (c) => {
            return (
              c &&
              c.title &&
              c.title.toLowerCase &&
              c.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          })?.slice(0, 30),
        );
      } else {
        // let _coursesUIData = [];
        // newDataCourses.map((course) => {
        //   _coursesUIData.push(
        //     getCoursesUIData({course, dateIsoToTime, dateIsoToDate, dateIsoToNormalDate}),
        //   );
        // });
        setCourseList(newDataCourses?.slice(0, 30));
      }
    }
  } else if (dataCourses == 'ERR_NETWORK' && isOnline) {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const fetchCoursesListWithParamsLP = async (props) => {
  const configData = marketEnvConfig();

  const {
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyStateLP,
  } = props;
  let endpoint = `/admin/api/v1/learning-paths?locale=${configData?.locale}`;
  if (sortBy) {
    endpoint += `&sortBy=${sortBy?.toLowerCase()}&states=${bodyVar?.states?.toString()}`;
  }
  const dataCourses = await makeRequest({
    method: 'GET',
    endpoint,
  });
  if (dataCourses != 'ERR_NETWORK' && dataCourses) {
    let newDataCourses = [];
    newDataCourses = dataCourses?.data;
    let tilesLables = [];
    if (tilesFilterArray !== []) {
      for (const ele of tilesFilterArray) {
        if (ele?.checked) {
          tilesLables.push(ele?.title);
        }
      }
      if (tilesLables?.length > 0) {
        newDataCourses = dataCourses?.data.filter((ele) => {
          return tilesLables.includes(ele?.state);
        });
      }
    }
    setLearningPathAPIResponse(newDataCourses?.slice(0, 30));
    setLearningPathAPIResponseCopy(newDataCourses?.slice(0, 30));
    setSearchFilterEmptyStateLP(true);
  }
};

export const fetchCountriesList = async (makeRequest, remoteConfig) => {
  const configData = marketEnvConfig();
  if (remoteConfig?.user_group_country_targeting) {
    const fetchCountry = await makeRequest({
      method: 'GET',
      endpoint: `/api/v1/market/countries`,
    });
    let country;
    let countryCodeString = '';
    if (fetchCountry?.data) {
      country = Object.entries(fetchCountry?.data)
        .map(([countryName, countryCode]) => ({
          countryName,
          countryCode,
          checked: true,
        }))
        .sort((a, b) => (a.countryName > b.countryName ? 1 : -1))
        .forEach((element) => {
          countryCodeString += element.checked ? `&countryCodes=${element.countryCode}` : '';
        });
    }
    return countryCodeString;
  } else {
    let countryCodeString = '';
    let country = [
      {
        countryName: configData?.name,
        countryCode: configData?.code.toUpperCase(),
        checked: true,
      },
    ];
    country.forEach((element) => {
      countryCodeString += element.checked ? `&countryCodes=${element.countryCode}` : '';
    });
    return countryCodeString;
  }
};

export const fetchAudienceGroupList = async (props) => {
  const {
    sortBy,
    makeRequest,
    setSearchFilterEmptyState,
    searchUserGroup,
    setAudienceGroupListCopy,
    setAudienceGroupList,
    setErrorStateTemplate,
    isOnline,
    remoteConfig,
  } = props;
  const configData = marketEnvConfig();

  const countryList = await fetchCountriesList(makeRequest, remoteConfig);
  //need to make this dynamic
  const endpoint = `/admin/api/v1/audience-groups?sortBy=oldest_to_newest${
    countryList?.length > 0 && countryList !== undefined
      ? countryList
      : `&countryCodes=${configData?.code?.toUpperCase()}`
  }`;
  const dataCourses = await makeRequest({
    method: 'GET',
    endpoint,
  });
  if (dataCourses != 'ERR_NETWORK' && dataCourses) {
    setSearchFilterEmptyState(true);
    if (searchUserGroup && searchUserGroup.length && searchUserGroup.length >= 1) {
      let result;
      result = dataCourses?.data?.filter((user) =>
        user.audienceGrpName.toLowerCase().includes(searchUserGroup.toLowerCase()),
      );
      //for checking length of audi group name
      result?.sort(function (one, other) {
        return one?.audienceGrpName?.length - other?.audienceGrpName?.length;
      });
      //for checking the serach char index in audi group name
      result?.sort(function (one, other) {
        return (
          one.audienceGrpName?.toLowerCase().indexOf(searchUserGroup?.toLowerCase()) -
          other.audienceGrpName?.toLowerCase().indexOf(searchUserGroup?.toLowerCase())
        );
      });
      setAudienceGroupList(result);
      setAudienceGroupListCopy(dataCourses?.data);
    } else {
      setAudienceGroupList(dataCourses?.data);
      setAudienceGroupListCopy(dataCourses?.data);
    }

    setErrorStateTemplate({
      variant: '',
      status: false,
    });
  } else if (dataCourses == 'ERR_NETWORK' && isOnline) {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const fetchLearningPathList = async (props) => {
  const configData = marketEnvConfig();

  const {
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyStateLP,
  } = props;
  const data = await makeRequest({
    method: 'GET',
    endpoint: `/admin/api/v1/learning-paths?locale=${configData?.locale}`,
  });
  if (data != 'ERR_NETWORK' && data) {
    setLearningPathAPIResponse(data?.data?.slice(0, 30));
    setLearningPathAPIResponseCopy(data?.data?.slice(0, 30));
    setSearchFilterEmptyStateLP(true);
  }
};

export const fetchCategories = async (props) => {
  const {makeRequest, filterData, findIndex, setFilterData} = props;
  const data = await makeRequest({
    method: 'GET',
    endpoint: '/api/v1/categories',
  });
  if (data) {
    let _filterData = filterData;
    let _filtersList = _filterData.filtersList;
    let _tempFiltersList = [];
    let index = findIndex(_filtersList, ['filterType', 'CHECKBOX']);
    if (index !== -1) {
      data &&
        data?.data?.length &&
        data?.data?.length > 0 &&
        data?.data?.map((dataItem, _index) => {
          const {id = '', title = ''} = dataItem;
          _tempFiltersList.push({
            index: _index,
            id: id,
            label: title,
            title: title,
            filterParameter: 'category',
            checked: false,
            typeOfFilter: 'CHECKBOX',
          });
        });
      _filtersList[index].filterTypeData.sectionData = _tempFiltersList;
    }

    setFilterData(_filterData);
  }
};

export const fetchCategoriesLP = async (props) => {
  const {makeRequest, filterDataLP, findIndex, setFilterDataLP} = props;
  const data = await makeRequest({
    method: 'GET',
    endpoint: '/api/v1/categories',
  });
  if (data) {
    let _filterData = filterDataLP;
    let _filtersList = _filterData.filtersList;
    let _tempFiltersList = [];
    let index = findIndex(_filtersList, ['filterType', 'CHECKBOX']);
    if (index !== -1) {
      data &&
        data?.data?.length &&
        data?.data?.length > 0 &&
        data?.data?.map((dataItem, _index) => {
          const {id = '', title = ''} = dataItem;
          _tempFiltersList.push({
            index: _index,
            id: id,
            label: title,
            title: title,
            filterParameter: 'category',
            checked: false,
            typeOfFilter: 'CHECKBOX',
          });
        });
      _filtersList[index].filterTypeData.sectionData = _tempFiltersList;
    }
    setFilterDataLP(_filterData);
  }
};

export const fetchCoursesList = async (props) => {
  const configData = marketEnvConfig();

  const {
    makeRequest,
    setShowEmptyTemplateFor,
    setSearchFilterEmptyStatecourses,
    setCourseFullList,
    setCourseListDefaultFilter,
    setCourseListCopy,
    setCourseList,
    setShowViewAllCardView,
    setShowViewAllListView,
    setErrorStateTemplate,
    isOnline,
  } = props;
  const data = await makeRequest({
    method: 'POST',
    endpoint: `/admin/api/v1/courses?locale=${configData?.locale}`,
    body: JSON.stringify({
      apiAction: 'FETCH_COURSES',
      apiMethod: 'POST',
    }),
  });
  if (data != 'ERR_NETWORK' && data) {
    if (data?.data?.length === 0) {
      setShowEmptyTemplateFor('COURSES');
      setCourseList([]);
    } else {
      setSearchFilterEmptyStatecourses(true);
      setCourseFullList(data?.data);
      setCourseListDefaultFilter(data?.data);
      setCourseList(data?.data?.slice(0, 30));
      setCourseListCopy(data?.data?.slice(0, 30));
      if (data?.data?.length > 30) {
        setShowViewAllCardView(true);
        setShowViewAllListView(true);
      }
    }
  } else if (data == 'ERR_NETWORK' && isOnline) {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const handleSearchInputUGM = (props) => {
  const {event, setSearchUserGroup, setPredictedSearchUGM, audienceGroupList} = props;
  setSearchUserGroup(event?.target?.value);
  setPredictedSearchUGM('');

  let tempPredition = audienceGroupList?.map((item) => ({
    id:
      item?.audienceGroupId && item?.audienceGroupId !== null && item.audienceGroupId !== undefined
        ? item?.audienceGroupId
        : '',
    label:
      item?.audienceGrpName && item?.audienceGrpName !== null && item.audienceGrpName !== undefined
        ? item?.audienceGrpName
        : '',
  }));
  if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
    setPredictedSearchUGM(tempPredition ? tempPredition : {});
  } else {
    setPredictedSearchUGM([]);
  }
};

export const getCoursesUIData = (props) => {
  const {dateIsoToTime, dateIsoToDate, dateIsoToNormalDate, course} = props;
  const {courseId, course_state, description, launch_date, thumbnail, title, bannerImage} = course;

  const timeToShow = dateIsoToTime(launch_date);
  const dateToShowForListView = dateIsoToDate(launch_date);
  const dateToShowForCardView = dateIsoToNormalDate(launch_date);

  let className = '';
  switch (course_state) {
    case 'Live':
      className = 'live';
      break;
    case 'Offline':
      className = 'offline';
      break;
    case 'Draft':
      className = 'draft';
      break;
    case 'ARCHIVE':
      className = 'archieve';
      break;
  }

  return {
    courseId,
    course_state,
    description,
    thumbnail,
    title,
    timeToShow,
    dateToShowForListView,
    dateToShowForCardView,
    className,
    bannerImage,
  };
};

export const handleSearch = (props) => {
  const {value, setSearch, setCourses, courses, setLearning, setAudience, learning, audience} =
    props;

  if (value === 'courses') {
    setSearch('');
    setCourses(!courses);
    setLearning(false);
    setAudience(false);
  } else if (value === 'learning') {
    setCourses(false);
    setLearning(!learning);
    setAudience(false);
  } else if (value === 'audience') {
    setCourses(false);
    setLearning(false);
    setAudience(!audience);
  } else {
    setCourses(false);
    setLearning(false);
    setAudience(false);
  }
  setSearch('');
};

export const handleFilter = (props) => {
  const {value, setFilterCourses} = props;
  if (value === 'courses') {
    setFilterCourses(true);
    // setFilterLearning(false);
    // setFilterAudience(false);
  } else if (value === 'learning') {
    setFilterCourses(false);
    // setFilterLearning(true);
    // setFilterAudience(false);
  } else if (value === 'audience') {
    setFilterCourses(false);
    // setFilterLearning(false);
    // setFilterAudience(true);
  } else {
    setFilterCourses(false);
    // setFilterLearning(false);
    // setFilterAudience(false);
  }
};

export const handleFilterApplyNewLP = async (props) => {
  const {
    params,
    filterDataLP,
    setFilterDataLP,
    setFilterDataTempLP,
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyStateLP,
    setIsLoading,
  } = props;
  setIsLoading(true);
  let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
  const categoryId = [];
  let categoriesArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');

  for (const ele of tilesFilterArray) {
    ele.checked && categoryId.push(ele.id);
  }
  let bodyVar = {
    states: categoryId,
  };
  let sortBy = '';
  let sortByName = 'Recent Activity';
  let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');
  radioFilterArray.forEach((ele) => {
    switch (ele?.name) {
      case 'Recent Activity':
        (sortBy = 'Recent_Activity'), (sortByName = 'Recent Activity');
        break;
      case 'A - Z':
        (sortBy = 'A_Z'), (sortByName = 'A - Z');
        break;
      case 'Z - A':
        (sortBy = 'Z_A'), (sortByName = 'Z - A');
        break;
      case 'Newest To Oldest':
        (sortBy = 'Newest_To_Oldest'), (sortByName = 'Newest To Oldest');
        break;
      case 'Oldest To Newest':
        (sortBy = 'Oldest_To_Newest'), (sortByName = 'Oldest To Newest');
        break;
      case 'Sort by Code (Ascending)':
        (sortBy = 'content_code_asc'), (sortByName = 'Sort by Code (Ascending)');
        break;
      case 'Sort by Code (Descending)':
        (sortBy = 'content_code_desc'), (sortByName = 'Sort by Code (Descending)');
        break;
      default:
        sortBy = 'Recent_Activity';
        break;
    }
  });
  let categoriesData = filterDataLP.filtersList[1].filterTypeData.sectionData;
  let sortByData = filterDataLP.filtersList[0].filterTypeData.sectionData;
  sortByData.forEach((data) => {
    if (data?.name == sortByName) {
      data.checked = true;
    } else {
      data.checked = false;
    }
  });
  const _filterData = {
    tiles: tilesFilterArray,
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: sortByData,
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: categoriesData,
        },
      },
    ],
  };
  setFilterDataLP(_filterData);
  setFilterDataTempLP(new Object(_filterData));
  fetchCoursesListWithParamsLP({
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyStateLP,
  });
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
};

export const handleFilterApplyNew = async (props) => {
  const {
    params,
    filterData,
    setFilterDataTemp,
    setFilterData,
    makeRequest,
    setShowEmptyTemplateFor,
    setSearchFilterEmptyStatecourses,
    setCourseListDefaultFilter,
    setErrorStateTemplate,
    setCourseFullList,
    setCourseListCopy,
    setCourseList,
    setShowViewAllListView,
    setShowViewAllCardView,
    search,
    filter,
    dateIsoToTime,
    dateIsoToDate,
    dateIsoToNormalDate,
    isOnline,
    setIsLoading,
  } = props;
  setIsLoading(true);
  let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
  const categoryId = [];
  let categoriesArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');
  for (const ele of tilesFilterArray) {
    ele.checked && categoryId.push(ele.id);
  }
  let bodyVar = {
    states: categoryId,
  };
  let sortBy = '';
  let sortByName = 'Recent Activity';
  let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');
  radioFilterArray.forEach((ele) => {
    switch (ele?.name) {
      case 'Recent Activity':
        (sortBy = 'Recent_Activity'), (sortByName = 'Recent Activity');
        break;
      case 'A - Z':
        (sortBy = 'A_Z'), (sortByName = 'A - Z');
        break;
      case 'Z - A':
        (sortBy = 'Z_A'), (sortByName = 'Z - A');
        break;
      case 'Newest To Oldest':
        (sortBy = 'Newest_To_Oldest'), (sortByName = 'Newest To Oldest');
        break;
      case 'Oldest To Newest':
        (sortBy = 'Oldest_To_Newest'), (sortByName = 'Oldest To Newest');
        break;
      case 'Sort by Course Code (Ascending)':
        (sortBy = 'content_code_asc'), (sortByName = 'Sort by Course Code (Ascending)');
        break;
      case 'Sort by Course Code (Descending)':
        (sortBy = 'content_code_desc'), (sortByName = 'Sort by Course Code (Descending)');
        break;
      default:
        sortBy = 'Recent_Activity';
        break;
    }
  });
  let categoriesData = filterData.filtersList[1].filterTypeData.sectionData;
  let sortByData = filterData.filtersList[0].filterTypeData.sectionData;
  sortByData.forEach((data) => {
    if (data?.name == sortByName) {
      data.checked = true;
    } else {
      data.checked = false;
    }
  });
  const _filterData = {
    tiles: tilesFilterArray,
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: sortByData,
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: categoriesData,
        },
      },
    ],
  };
  setFilterData(_filterData);
  setFilterDataTemp(new Object(_filterData));
  await fetchCoursesListWithParams({
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setShowEmptyTemplateFor,
    setSearchFilterEmptyStatecourses,
    setCourseListDefaultFilter,
    setErrorStateTemplate,
    setCourseFullList,
    setCourseListCopy,
    setCourseList,
    setShowViewAllListView,
    setShowViewAllCardView,
    search,
    filter,
    dateIsoToTime,
    dateIsoToDate,
    dateIsoToNormalDate,
    isOnline,
  });
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
};

export const handleFilterApplyUGM = async (props) => {
  const {
    params,
    filterDataUGM,
    setFilterDataUGM,
    setFilterDataTempUGM,
    makeRequest,
    setSearchFilterEmptyState,
    searchUserGroup,
    setAudienceGroupListCopy,
    setAudienceGroupList,
    setErrorStateTemplate,
    isOnline,
    setIsLoading,
  } = props;
  setIsLoading(true);
  let sortBy = '';
  let sortByName = 'Recent Activity';
  let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');
  radioFilterArray.forEach((ele) => {
    switch (ele?.name) {
      case 'Recent Activity':
        (sortBy = 'Recent_Activity'), (sortByName = 'Recent Activity');
        break;
      case 'A - Z':
        (sortBy = 'A_Z'), (sortByName = 'A - Z');
        break;
      case 'Z - A':
        (sortBy = 'Z_A'), (sortByName = 'Z - A');
        break;
      case 'Most Courses':
        (sortBy = 'most_courses'), (sortByName = 'Most Courses');
        break;
      case 'Least Courses':
        (sortBy = 'least_courses'), (sortByName = 'Least Courses');
        break;
      default:
        sortBy = 'Recent_Activity';
        break;
    }
  });
  let sortByData = filterDataUGM.filtersList[0].filterTypeData.sectionData;
  sortByData.forEach((data) => {
    if (data?.name == sortByName) {
      data.checked = true;
    } else {
      data.checked = false;
    }
  });
  const _filterData = {
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: sortByData,
        },
      },
    ],
  };
  setFilterDataUGM(_filterData);
  setFilterDataTempUGM(new Object(_filterData));
  await fetchAudienceGroupList({
    sortBy,
    makeRequest,
    setSearchFilterEmptyState,
    searchUserGroup,
    setAudienceGroupListCopy,
    setAudienceGroupList,
    setErrorStateTemplate,
    isOnline,
  });
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
};

export const handleToggleSwitch = (props) => {
  const {value, setIsCardView, setIsListView, setShowViewAllListView, setShowViewAllCardView} =
    props;
  if (value === 'listView') {
    setIsCardView(false);
    setIsListView(true);
    setShowViewAllListView(true);
  } else {
    setIsCardView(true);
    setIsListView(false);
    setShowViewAllCardView(true);
  }
};

export const handleSearchInputLP = (props) => {
  const {event, setSearchLearningPath, learningPathAPIResponse, setPredictedSearchLP} = props;
  setSearchLearningPath(event?.target?.value);
  //setPredictedSearchLP('');

  let tempPredition = learningPathAPIResponse?.map((item) => ({
    id:
      item?.learningPathId && item?.learningPathId !== null && item.learningPathId !== undefined
        ? item?.learningPathId
        : '',
    label: item?.title && item?.title !== null && item.title !== undefined ? item?.title : '',
  }));
  if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
    setPredictedSearchLP(tempPredition ? tempPredition : {});
  } else {
    setPredictedSearchLP([]);
  }
};

export const handleSearchInput = (props) => {
  const {event, setSearch, setPredictedSearch, courseList} = props;
  setSearch(event?.target?.value);
  setPredictedSearch([]);
  let tempPredition = courseList.map((item) => ({
    id:
      item?.courseId && item?.courseId !== null && item.courseId !== undefined
        ? item?.courseId
        : '',
    label: item?.title && item?.title !== null && item.title !== undefined ? item?.title : '',
  }));

  if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
    setPredictedSearch(tempPredition ? tempPredition : {});
  } else {
    setPredictedSearch([]);
  }
};

export const handleClickOutside = (props) => {
  const {e, setFilterDropdown} = props;

  const el = document.getElementById('divParentOfFilter');

  if (el !== null) {
    const outside = !el?.contains(e.target);
    if (outside) {
      if (
        !e.target.className.includes('Portal_filter') &&
        !e.target.className.includes('Filter') &&
        !e.target.className.includes('RadioButtons') &&
        !e.target.className.includes('Checkbox') &&
        !e.target.className.includes('RadioButtons_container')
      ) {
        setFilterDropdown(false);
      }
    }
  }
};

export const handleClickOutsideUGM = (props) => {
  const {e, setFilterDropdownLP, setFilterDropdownUGM} = props;
  const el = document.getElementById('divParentOfFilterUGM');
  if (el !== null) {
    const outside = !el?.contains(e.target);
    if (outside) {
      if (
        !e.target.className.includes('Portal_filter') &&
        !e.target.className.includes('Filter') &&
        !e.target.className.includes('RadioButtons') &&
        !e.target.className.includes('Checkbox') &&
        !e.target.className.includes('RadioButtons_container')
      ) {
        setFilterDropdownUGM(false);
        setFilterDropdownLP(false);
      }
    }
  }
};

export const updateWindowDimensions = (props) => {
  const {setWidth, setHeight} = props;
  setWidth(window.innerWidth);
  setHeight(window.innerHeight);
};

export const handleFilters = (props) => {
  const {setFilterDropdown, filterDropdown} = props;
  setFilterDropdown(!filterDropdown);
};

export const getMobileViewCourseState = (props) => {
  const {state} = props;
  switch (state?.toLowerCase()) {
    case 'live':
      return 'live_card';

    case 'offline':
      return 'offline_card';

    case 'draft':
      return 'draft_card';

    case 'archive':
      return 'archive';

    default:
      return '';
  }
};

export const redirectCourses = (props) => {
  const {id, viewType, setSelectedNavigationOption, router} = props;

  setSelectedNavigationOption('COURSES-MANAGEMENT');
  router.push({
    pathname: '/coursesManagement',
    query: {id, viewType},
  });
};

export const redirectUserGroup = (props) => {
  const {id, viewType, setSelectedNavigationOption, router} = props;
  setSelectedNavigationOption('AUDIENCE-MANAGEMENT');
  router.push({
    pathname: '/UserManagement',
    query: {UserGroupId: id, viewType},
  });
};

export const redirectLearningPath = (props) => {
  const {id, viewType, setSelectedNavigationOption, router} = props;
  setSelectedNavigationOption('LEARNING-PATH');
  router.push({
    pathname: '/LearningPathModal',
    query: {learningPathId: id, viewType},
  });
};

export const initialFirstUseEffect = async (props) => {
  const {
    setOnline,
    setIsUserLoggedIn,
    setSelectedNavigationOption,
    makeRequest,
    setShowEmptyTemplateFor,
    setSearchFilterEmptyStatecourses,
    setCourseFullList,
    setCourseListDefaultFilter,
    setCourseListCopy,
    setCourseList,
    setShowViewAllCardView,
    setShowViewAllListView,
    setErrorStateTemplate,
    isOnline,
    filterData,
    findIndex,
    setFilterData,
    setFilterDataLP,
    filterDataLP,
    setSearchFilterEmptyState,
    searchUserGroup,
    setAudienceGroupListCopy,
    setAudienceGroupList,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyStateLP,
    setWidth,
    setFilterDropdownLP,
    setFilterDropdownUGM,
    router,
    setFilterDropdown,
    setIsLoading,
    remoteConfig,
    setAllLearningPathList
  } = props;
  setOnline(navigator.onLine);
  setIsUserLoggedIn(true);
  setSelectedNavigationOption('DASHBOARD');
  await fetchCoursesList({
    makeRequest,
    setShowEmptyTemplateFor,
    setSearchFilterEmptyStatecourses,
    setCourseFullList,
    setCourseListDefaultFilter,
    setCourseListCopy,
    setCourseList,
    setShowViewAllCardView,
    setShowViewAllListView,
    setErrorStateTemplate,
    isOnline,
  });
  await fetchCategories({
    makeRequest,
    filterData,
    findIndex,
    setFilterData,
  });
  await fetchCategoriesLP({
    makeRequest,
    filterDataLP,
    findIndex,
    setFilterDataLP,
  });
  await fetchAudienceGroupList({
    sortBy: 'oldest_to_newest',
    makeRequest,
    setSearchFilterEmptyState,
    searchUserGroup,
    setAudienceGroupListCopy,
    setAudienceGroupList,
    setErrorStateTemplate,
    isOnline,
    remoteConfig,
  });
  await fetchLearningPathList({
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyStateLP,
    setAllLearningPathList
  });

  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  // document.addEventListener("mousedown", closeSortAndFilterFlyout, true);
  // session management changes starts here
  const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));
  const {accessToken, idToken} = oktaToken;

  const diffInSessionTimeout = timeDifference(new Date(idToken?.expiresAt * 1000), new Date());

  console.log("token expiry time ", diffInSessionTimeout)

  const timeLeftForTokenExpiry = (accessToken?.expiresAt - Math.floor(Date.now() / 1000)) * 1000;

  console.log("timeLeftForTokenExpiry ", timeLeftForTokenExpiry);

  setTimeout(() => {
    localStorage.removeItem('okta-token-storage');
    console.log("DEBUG /login PortalUtils")

    router.push({
      pathname: '/login',
    });
  }, timeLeftForTokenExpiry);
  
  document.addEventListener(
    'mousedown',
    (e) => {
      handleClickOutside({
        e,
        setFilterDropdown,
      });
    },
    true,
  );
  document.addEventListener(
    'mousedown',
    (e) => {
      handleClickOutsideUGM({
        e,
        setFilterDropdownLP,
        setFilterDropdownUGM,
      });
    },
    true,
  );

  // session management changes ends here
  return () => {
    // Anything in here is fired on component unmount.
    window.removeEventListener('resize', () => {
      updateWindowDimensions({setWidth});
    });
    document.removeEventListener(
      'click',
      (e) => {
        handleClickOutside({
          e,
          setFilterDropdown,
        });
      },
      true,
    );
    document.removeEventListener(
      'mousedown',
      (e) => {
        handleClickOutside({
          e,
          setFilterDropdown,
        });
      },
      true,
    );
    document.removeEventListener(
      'click',
      (e) => {
        handleClickOutsideUGM({
          e,
          setFilterDropdownLP,
          setFilterDropdownUGM,
        });
      },
      true,
    );
    document.removeEventListener(
      'mousedown',
      (e) => {
        handleClickOutsideUGM({
          e,
          setFilterDropdownLP,
          setFilterDropdownUGM,
        });
      },
      true,
    );
    // document.removeEventListener("click", closeSortAndFilterFlyout, true);
    // document.removeEventListener("mousedown", closeSortAndFilterFlyout, true);
  };
};

export const tempCourseListUseEffect = (props) => {
  const {setCourseList, tempCourseList, setShowEmptyTemplateFor} = props;
  setCourseList(tempCourseList);
  if (tempCourseList.length == 0) {
    setShowEmptyTemplateFor('COURSES');
  }
};

export const courseListUseEffect = (props) => {
  const {changeCourseList, tempCourseList, setCourseList, courseList, setChangeCourseList} = props;
  if (changeCourseList && tempCourseList !== courseList) {
    setCourseList(tempCourseList);
    if (tempCourseList === courseList) {
      setChangeCourseList(false);
    }
  }
};

export const courseListAndSearchUseEffect = (props) => {
  const {courseList, search, setShowEmptyTemplateFor} = props;

  if (courseList?.length === 0 && search?.length > 0) {
    setShowEmptyTemplateFor('COURSES');
  } else {
    setShowEmptyTemplateFor('');
  }
};

export const searchUseEffect = (props) => {
  const {search, courseFullList, courseListCopy, setCourseList} = props;
  if (search && search.length && search.length >= 1) {
    let result;
    result = courseFullList.filter((user) =>
      user.title.toLowerCase().includes(search.toLowerCase()),
    );
    //for checking length of audi group name
    result?.sort(function (one, other) {
      return one?.title?.length - other?.title?.length;
    });
    //for checking the serach char index in audi group name
    result?.sort(function (one, other) {
      return (
        one.title?.toLowerCase().indexOf(search?.toLowerCase()) -
        other.title?.toLowerCase().indexOf(search?.toLowerCase())
      );
    });
    setCourseList(result);
  } else {
    setCourseList(courseListCopy);
  }
};

export const searchUserGroupUseEffect = (props) => {
  const {searchUserGroup, audienceGroupListCopy, setAudienceGroupList} = props;
  if (searchUserGroup && searchUserGroup.length && searchUserGroup.length >= 1) {
    let result;
    result = audienceGroupListCopy.filter((user) =>
      user.audienceGrpName.toLowerCase().includes(searchUserGroup.toLowerCase()),
    );
    //for checking length of audi group name
    result?.sort(function (one, other) {
      return one?.audienceGrpName?.length - other?.audienceGrpName?.length;
    });
    //for checking the serach char index in audi group name
    result?.sort(function (one, other) {
      return (
        one.audienceGrpName?.toLowerCase().indexOf(searchUserGroup?.toLowerCase()) -
        other.audienceGrpName?.toLowerCase().indexOf(searchUserGroup?.toLowerCase())
      );
    });
    setAudienceGroupList(result);
  } else {
    setAudienceGroupList(audienceGroupListCopy);
  }
};

export const searchLearningPathUseEffect = (props) => {
  const {searchLearningPath, learningPathAPIResponseCopy, setLearningPathAPIResponse} = props;
  if (searchLearningPath && searchLearningPath.length && searchLearningPath.length >= 1) {
    let result;
    result = learningPathAPIResponseCopy.filter((user) =>
      user.title.toLowerCase().includes(searchLearningPath.toLowerCase()),
    );
    //for checking length of LP group name
    result?.sort(function (one, other) {
      return one?.title?.length - other?.title?.length;
    });
    //for checking the serach char index in LP group name
    result?.sort(function (one, other) {
      return (
        one.title?.toLowerCase().indexOf(searchLearningPath?.toLowerCase()) -
        other.title?.toLowerCase().indexOf(searchLearningPath?.toLowerCase())
      );
    });
    setLearningPathAPIResponse(result);
  } else {
    setLearningPathAPIResponse(learningPathAPIResponseCopy);
  }
};
