import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import styles from './Drawer.module.scss';
import DrawerFailScreen from '../../shared/drawerFailScreen';
import StatusLabel from '../../shared/StatusLabel';
import CourseTypeBannerViewOnly from '../../shared/CourseTypeBannerViewOnly';
import config from '../../../config';
import DrawerDropDownListWithInfoIcon from '../../shared/DrawerDropDownListWithInfoIcon';
import {
  dummyFunction,
  drawerOverlayCalculatedHeight,
  mobDrawerToOrignalState,
  drawerCalculatedHeight,
  pullbox,
} from '../../utils/commonUtil';
import CreateCustomizedUserGroup from '../CustomizedUserGroup/CreateCustomizedUserGroup';
import { useDispatch, useSelector } from 'react-redux';
import { setIsCustomUserGroup, setIsEditUserGroup } from '../../../redux/actions/userGroupManagementAction';
import EditCustomizedUserGroup from '../CustomizedUserGroup/EditCustomizedUserGroup';
import { deleteCustomUserGroup, downloadCustomUserList } from '../../../utils/customUserUtils';


export const handleClickOutside = (props) => {
  const {closeDrawerModal, e} = props;
  // console.log(">>>> >>>> handleClickOutside >>>> props",props)
  const el = document.getElementById('divParentOfFilter');
  if (el !== null) {
    const outside = !el?.contains(e.target);
    if (outside) {
      if (e.target.className.includes('Drawer_custom')) {
        closeDrawerModal();
      }
    }
  }
};

const DrawerComponent = (props) => {
  const {
    drawerDetails = {},
    drawerOpen = false,
    drawerClose,
    isAPIFailed = false,
    drawerTryAgainCall = dummyFunction,
    drawerIdForBrokenLink = '',
    remoteConfig,
    audienceGroupList,
    setAudienceDetails,
    countryAvailability,
  } = props;

  const router = useHistory();
  const {query = {}} = router.location;
  const {currentEnv} = config;

  const [isInfoIisClicked, setInfoIisClicked] = useState(false);
  const [isCoursesDrawerList, setCoursesDrawerList] = useState(true);
  const [width, setWidth] = useState(0);
  const [drawerHeight, setDrawerHeight] = useState('');
  const [isInfoIisClickedAudience, setInfoIisClickedAudience] = useState(false);
  const [isLearninngPathDrawerList, setLearninngPathDrawerList] = useState(true);

  const {isCreateUserGroup, isCustomUserGroup, isEditUserGroup} = useSelector(
    (state) => state.userGroupManagement,
  );

  const dispatch = useDispatch();

  const [isConfirm, setIsConfirm] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setCoursesDrawerList(true);
    setLearninngPathDrawerList(true);
  }, [drawerDetails]);
  const closeDrawerModal = () => {
    drawerClose(!drawerOpen);
  };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      handleClickOutside({e: e, closeDrawerModal: closeDrawerModal});
    });
    setDrawerHeight(140);
  }, [drawerOpen]);

  useEffect(() => {
    setIsConfirm(false);
    setInputValue("")
  }, [drawerDetails]);

  const openManageCourses = () => {
    router.push({
      pathname: '/ManageCourses',
      query: {
        isManageCourseOpen: true,
        AudienceGroupId: drawerDetails?.audienceGroupId,
        AudienceGrpName: drawerDetails?.audienceGrpName,
        CourseCount: drawerDetails?.courseCount,
        countryCodeIdsArray: drawerDetails?.countryCodeIdsArray,
        countryCodeAllIds: drawerDetails?.countryCodeAllIds,
        remoteConfig: remoteConfig,
      },
    });
  };

  const openManageLearningPath = () => {
    router.push({
      pathname: '/ManageLearningPath',
      query: {
        isManageCourseOpen: true,
        AudienceGroupId: drawerDetails?.audienceGroupId,
        AudienceGrpName: drawerDetails?.audienceGrpName,
        CourseCount: drawerDetails?.learningPathCount,
        countryCodeIdsArray: drawerDetails?.countryCodeIdsArray,
        countryCodeAllIds: drawerDetails?.countryCodeAllIds,
        remoteConfig: remoteConfig,
      },
    });
  };

  useEffect(() => {
    if (drawerHeight >= 600) {
      closeDrawerModal();
    }
  }, [drawerHeight]);

  const cancelHandler = () => {
    drawerClose(false);
  };

  const deleteHandler = () => {
    setIsConfirm(true);
  };

  const handleConfirmDelete = () => {
    deleteCustomUserGroup(drawerDetails, drawerClose, setIsConfirm, dispatch);
  };

  const downloadUserGroupList = () => {
    downloadCustomUserList(drawerDetails);
  };

  return (
    <React.Fragment>
      {drawerOpen && (
        <div id="divParentOfFilter" className={[styles.drawer_container, ''].join(' ')}>
          <div
            className={styles.custom_overlay}
            style={drawerOverlayCalculatedHeight({drawerHeight: drawerHeight})}
          ></div>
          <div
            className={`${styles.drawer_wrapper} ${!countryAvailability ? styles.drawerHeight : ''}`}
            style={drawerCalculatedHeight({drawerHeight: drawerHeight})}
          >
            <div
              className={styles.mob_bar}
              draggable
              onTouchMove={(e) => {
                pullbox({e: e, setDrawerHeight: setDrawerHeight});
              }}
              onTouchEnd={() => {
                mobDrawerToOrignalState({setDrawerHeight: setDrawerHeight});
              }}
            >
              <div className={styles.line_bar} onClick={() => closeDrawerModal()}></div>
            </div>
            {isConfirm ? (
              <>
                <div className={[styles.middle_container, ''].join(' ')}>
                  <div className={styles.header}>
                    <div className={styles.title}>Delete {drawerDetails?.audienceGrpName}?</div>
                    <div className={styles.close_wrapper} onClick={() => closeDrawerModal()}>
                      <div className={styles.close_icon} />
                    </div>
                  </div>
                  <div className={styles.confirm_txt}>
                    You are about to delete the custom user group{' '}
                    <span
                      style={{fontWeight: 'bold'}}
                    >{`"${drawerDetails?.audienceGrpName}"`}</span>
                    . This action cannot be undone.
                  </div>

                  <div className={styles.confirm_txt}>
                    To permanently delete this user group, type “DELETE”.
                  </div>
                  <input
                    type={'text'}
                    placeholder={'Type DELETE here'}
                    className={styles.input}
                    maxLength="101"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <div className={styles.edit_btn}>
                    <div className={styles.cancel_btn} onClick={() => setIsConfirm(false)}>
                      Cancel
                    </div>
                    <div
                      className={`${styles.save_btn} ${
                        inputValue !== 'DELETE' ? styles.disabled : ''
                      }`}
                      onClick={() => inputValue === 'DELETE' && handleConfirmDelete()}
                    >
                      Permanently Delete User Group
                    </div>
                  </div>
                </div>
              </>
            ) : !isAPIFailed ? (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.title}>
                    {isCreateUserGroup ? `Create User Group` : `User Group Details`}
                  </div>
                  <div className={styles.close_wrapper} onClick={() => closeDrawerModal()}>
                    <div className={styles.close_icon} />
                  </div>
                </div>
                {!isCreateUserGroup && !isEditUserGroup && (
                  <div style={{display: 'flex'}}>
                    <div className={styles.course_title}>{drawerDetails?.audienceGrpName}</div>
                    {isCustomUserGroup ? (
                      <div
                        className={styles.edit_group}
                        onClick={() => {
                          dispatch(setIsEditUserGroup(true));
                          dispatch(setIsCustomUserGroup(false));
                        }}
                      ></div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
                {!isCreateUserGroup && !isEditUserGroup && (
                  <div className={styles.description_wrapper}>
                    <div className={styles.label}>Description</div>
                    <div className={styles.value}>{drawerDetails?.audienceGrpDesc}</div>
                  </div>
                )}

                {isCreateUserGroup && <CreateCustomizedUserGroup audienceGroupList={audienceGroupList} onCancelCb={cancelHandler} setAudienceDetails={setAudienceDetails}/>}
                {(isEditUserGroup) && <EditCustomizedUserGroup audienceGroupList={audienceGroupList} onCancelCb={cancelHandler} onDeleteCb={deleteHandler} setAudienceDetails={setAudienceDetails} allArchive={drawerDetails.allArchive}/>}

                {isCustomUserGroup ? (
                  <>
                    <div className={styles.description_wrapper}>
                      <div className={styles.label}>User Group List</div>
                    </div>
                    <div
                      className={styles.download_btn_wrapper}
                      onClick={downloadUserGroupList}
                    >
                      <div className={styles.attachment}></div>
                      <div>Download Current User Group List</div>
                    </div>
                    <div className={styles.divider}></div>
                  </>
                ) : (
                  <></>
                )}

                {!isCreateUserGroup && (
                  <div
                    className={[styles.img_instances_container, styles.lp_user_grp_mgt, ''].join(
                      ' ',
                    )}
                  >
                    {' '}
                    {/* LP  => add this class lp_user_grp_mgt */}
                    {drawerDetails?.courses?.length > 0 && (
                      <DrawerDropDownListWithInfoIcon
                        openManageCourses={openManageCourses}
                        setCoursesDrawerList={setCoursesDrawerList}
                        isCoursesDrawerList={isCoursesDrawerList}
                        drawerDetailsList={drawerDetails?.courses}
                        drawerDetailsCount={drawerDetails?.courseCount}
                        isInfoIisClicked={isInfoIisClicked}
                        width={width}
                        setInfoIisClicked={setInfoIisClicked}
                        title={'Courses'}
                        headerOfBanner={'Course'}
                      />
                    )}
                    {drawerDetails?.courses?.length === 0 ? (
                      <div className={styles.add_course_btn_wrapper}>
                        <div
                          className={styles.add_course_ag_btn}
                          onClick={() => {
                            openManageCourses();
                          }}
                        >
                          <div className={styles.plus_icon}></div>
                          <div className={styles.txt}>Add Courses to this User Group</div>
                        </div>
                      </div>
                    ) : null}
                    {/* Manage LP Starts */}
                    {drawerDetails?.learningPaths?.length > 0 && (
                      <DrawerDropDownListWithInfoIcon
                        openManageCourses={openManageLearningPath}
                        setCoursesDrawerList={setLearninngPathDrawerList}
                        isCoursesDrawerList={isLearninngPathDrawerList}
                        drawerDetailsList={drawerDetails?.learningPaths}
                        drawerDetailsCount={drawerDetails?.learningPathCount}
                        isInfoIisClicked={isInfoIisClickedAudience}
                        width={width}
                        setInfoIisClicked={setInfoIisClickedAudience}
                        title={'Learning Paths'}
                        headerOfBanner={'Learning Path'}
                      />
                    )}
                    {drawerDetails?.learningPaths?.length === 0 ? (
                      <div className={styles.add_course_btn_wrapper}>
                        <div
                          className={styles.add_course_ag_btn}
                          onClick={() => {
                            openManageLearningPath();
                          }}
                        >
                          <div className={styles.plus_icon}></div>
                          <div className={styles.txt}>Add Learning Paths to this User Group</div>
                        </div>
                      </div>
                    ) : null}
                    {/* Manage LP Ends */}
                  </div>
                )}
              </div>
            ) : (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.title}>User Group Details</div>
                  <div className={styles.close_wrapper} onClick={() => closeDrawerModal()}>
                    <div className={styles.close_icon} />
                  </div>
                </div>
                <DrawerFailScreen
                  drawerId={drawerIdForBrokenLink}
                  drawerTryAgainCall={(item) => {
                    drawerTryAgainCall(item);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

DrawerComponent.propTypes = {};

export default DrawerComponent;
