import React from 'react';
import LoadingShimmer from '../../shared/LoadingShimmer';
import LoadingShimmerList from '../../shared/LoadingShimmerList';
import styles from '../Portal.module.scss';
import ViewAllComponent from '../ViewAllComponent';
import { formatDateInThailandTimezone } from '../../../utils/date';
import { IS_THAILAND } from '../../../utils/datePicker';

const CardAndListCourseView = (props) => {
  const {
    isLoading,
    setIsLoading,
    isListView,
    isCardView,
    showEmptyTemplateFor,
    courseList,
    redirectCourses,
    setSelectedNavigationOption,
    router,
    dateIsoToNormalDate,
    isSearchFilterEmptyStateCourses,
    EmptyScreenComponent,
    dateIsoToTime,
    dateIsoToDate,
    courses,
    search,
    predictedSearch,
    Hint,
    handleSearchInput,
    setSearch,
    setPredictedSearch,
    setLearning,
    setAudience,
    learning,
    audience,
    setNotificationData,
    handleSearch,
    setCourses,
    sortAndFilterFlyout,
    filterData,
    setFilterDataTemp,
    setFilterData,
    makeRequest,
    setShowEmptyTemplateFor,
    setSearchFilterEmptyStatecourses,
    setCourseListDefaultFilter,
    setErrorStateTemplate,
    setCourseFullList,
    setCourseListCopy,
    setCourseList,
    setShowViewAllListView,
    setShowViewAllCardView,
    filterCourses,
    setFilterDropdown,
    setFilterDropdownLP,
    setFilterDropdownUGM,
    handleFilters,
    filterDropdown,
    FilterComponent,
    headingName,
    handleFilterApplyNew,
    filter,
    sortData,
    setIsCardView,
    setIsListView,
    handleToggleSwitch,
    isOnline,
    height,
  } = props;
  return (
    <React.Fragment>
      <div
        style={height ? {height: (70 / 100) * (height - 95) + 'px'} : null}
        className={[styles.card_wrapper, styles.courses_section].join(' ')}
      >
        <div className={styles.header_wrapper}>
          <div className={styles.txt}>Courses</div>
          <div className={styles.action_wrapper}>
            {/* Added class "outline" for search when user type border should highlighted */}
            {courses ? (
              <div
                className={[styles.search_wrapper, search.length > 0 ? styles.outline : null].join(
                  ' ',
                )}
              >
                <Hint options={predictedSearch} allowTabFill={true}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(event) => {
                      handleSearchInput({
                        event,
                        setSearch,
                        setPredictedSearch,
                        courseList,
                      });
                    }}
                    onKeyPress={(event) =>
                      handleSearchInput({
                        event,
                        setSearch,
                        setPredictedSearch,
                        courseList,
                      })
                    }
                    className={styles.search_input}
                    onKeyDown={(e) => {
                      if (e.keyCode == 13 && search?.length > 0 && search?.length < 3) {
                        setNotificationData({
                          show: false,
                          type: 'WARNING',
                          title: 'Insufficient Search Input',
                          description: 'To search for any Course, enter 3 or more characters.',
                        });
                      }
                    }}
                  />
                </Hint>
                <div className={styles.icon_wrapper}>
                  {/* here we added cross icon in search and it should be conditional once user                                                                                                                                                                                       start typing */}
                  <div
                    className={[styles.search, search.length > 0 ? styles.cross_icon : null].join(
                      ' ',
                    )}
                    onClick={() =>
                      handleSearch({
                        value: 'courses',
                        setSearch,
                        setCourses,
                        courses,
                        setLearning,
                        setAudience,
                        learning,
                        audience,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <div className={styles.icon_wrapper}>
                <div
                  className={styles.search_white}
                  onClick={() =>
                    handleSearch({
                      value: 'courses',
                      setSearch,
                      setCourses,
                      courses,
                      setLearning,
                      setAudience,
                      learning,
                      audience,
                    })
                  }
                />
              </div>
            )}
            <div
              className={styles.filter_main_wrapper}
              ref={sortAndFilterFlyout}
              id="divParentOfFilter"
            >
              <div
                className={[styles.icon_wrapper, filterCourses ? styles.selected : ''].join(' ')}
              >
                <div
                  className={styles.filter}
                  onClick={(e) => {
                    handleFilters({setFilterDropdown, filterDropdown});
                    setFilterDropdownUGM(false);
                    setFilterDropdownLP(false);
                  }}
                />
              </div>

              {filterDropdown ? (
                <FilterComponent
                  isPills={true}
                  headingName={headingName}
                  isLandingPage={true}
                  filterData={filterData}
                  sortData={sortData}
                  handleFilterApply={(params) => {
                    handleFilterApplyNew({
                      params,
                      filterData,
                      setFilterDataTemp,
                      setFilterData,
                      makeRequest,
                      setShowEmptyTemplateFor,
                      setSearchFilterEmptyStatecourses,
                      setCourseListDefaultFilter,
                      setErrorStateTemplate,
                      setCourseFullList,
                      setCourseListCopy,
                      setCourseList,
                      setShowViewAllListView,
                      setShowViewAllCardView,
                      search,
                      filter,
                      dateIsoToTime,
                      dateIsoToDate,
                      dateIsoToNormalDate,
                      isOnline,
                      setIsLoading,
                    });
                  }}
                  handleFilterCancle={() => setFilterDropdown(false)}
                />
              ) : null}
            </div>
            <div className={styles.toggle_wrapper}>
              <div
                className={[styles.toggle_section, isCardView ? styles.selected : ''].join(' ')}
                onClick={() =>
                  handleToggleSwitch({
                    value: 'cardView',
                    setIsCardView,
                    setIsListView,
                    setShowViewAllListView,
                    setShowViewAllCardView,
                  })
                }
              >
                <div className={styles.card_view} />
              </div>
              <div
                className={[styles.toggle_section, isListView ? styles.selected : ''].join(' ')}
                onClick={() =>
                  handleToggleSwitch({
                    value: 'listView',
                    setIsCardView,
                    setIsListView,
                    setShowViewAllListView,
                    setShowViewAllCardView,
                  })
                }
              >
                <div className={styles.list_view} />
              </div>
            </div>
          </div>
        </div>
        {isCardView && !isListView ? (
          <div className={styles.card_view_wrapper}>
            {showEmptyTemplateFor !== 'COURSES' && courseList?.length > 0 && !isLoading ? (
              <>
                {courseList?.map((courseItem, index) => {
                  return (
                    <div
                      key={index}
                      className={[styles.p_lr_10, 'col-lg-4 col-md-6 pull-left'].join(' ')}
                      onClick={() =>
                        redirectCourses({
                          id: courseItem?.courseId,
                          viewType: 'card',
                          setSelectedNavigationOption,
                          router,
                        })
                      }
                    >
                      <div
                        className={[
                          styles.card,
                          courseItem?.course_state === 'Live'
                            ? styles.live_card
                            : courseItem?.course_state === 'Offline'
                            ? styles.offline_card
                            : courseItem?.course_state === 'Draft'
                            ? styles.draft_card
                            : courseItem?.course_state === 'Archive'
                            ? styles.archive
                            : '',
                        ].join(' ')}
                      >
                        <div className={[styles.image_wrapper, styles.empty_state].join(' ')}>
                          {courseItem?.bannerImage ? (
                            <img src={courseItem?.bannerImage} alt={'portal card view'} />
                          ) : (
                            <div className={[styles.img, styles.empty_state_img].join(' ')}></div>
                          )}
                        </div>
                        <div className={styles.details_wrapper}>
                          <div className={styles.bottom_wrapper}>
                            <div className={styles.highlight}>{courseItem?.course_state}</div>
                            {courseItem?.course_state?.toLowerCase() !== 'draft' && (
                              <div className={styles.block_wrapper}>
                                {/* Here conditionally calendar class should be replaced by "archive_cal_icon", "offline_cal_icon" for different status*/}
                                <div
                                  className={[
                                    courseItem?.course_state?.toLowerCase() === 'live'
                                      ? styles.calendar
                                      : courseItem?.course_state?.toLowerCase() === 'offline'
                                      ? styles.offline_cal_icon
                                      : styles.archive_cal_icon,
                                  ]}
                                />
                                <div className={styles.date}>
                                {courseItem?.launch_date
                                    ? IS_THAILAND ? formatDateInThailandTimezone(courseItem?.launch_date, 'DD/MM/YYYY') : dateIsoToNormalDate(courseItem?.launch_date)
                                    : ''}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={styles.description}>{courseItem?.title}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <ViewAllComponent
                  isLoading={isLoading}
                  showEmptyTemplateFor={''}
                  list={courseList}
                  SelectedNavigationOptionText={'COURSES-MANAGEMENT'}
                  pageName="Courses"
                />
              </>
            ) : courseList.length === 0 && !isLoading ? (
              <EmptyScreenComponent />
            ) : (
              <LoadingShimmer count={30} flex30={true} />
            )}
          </div>
        ) : courseList.length === 0 && !isLoading ? (
          <EmptyScreenComponent />
        ) : (
          <div className={styles.list_view_wrapper}>
            {showEmptyTemplateFor !== 'COURSES' && (
              <div className={styles.tbl_header}>
                <div className={styles.tbl_head}>Image</div>
                <div className={styles.tbl_head}>Name</div>
                <div className={styles.tbl_head}>Relevant Date</div>
                <div className={styles.tbl_head}>Status</div>
              </div>
            )}
            <div className={styles.tbl_body}>
              {showEmptyTemplateFor !== 'COURSES' && courseList?.length > 0 && !isLoading ? (
                <>
                  {courseList?.map((courseItem, index) => (
                    <div
                      key={index}
                      className={styles.tbl_row}
                      onClick={() =>
                        redirectCourses({
                          id: courseItem?.courseId,
                          viewType: 'list',
                          setSelectedNavigationOption,
                          router,
                        })
                      }
                    >
                      <div className={styles.tbl_data}>
                        <div className={[styles.image_wrapper, styles.empty_state].join(' ')}>
                          {courseItem?.bannerImage ? (
                            <img src={courseItem?.bannerImage} alt="course thumbnail" />
                          ) : (
                            <div className={[styles.img, styles.empty_state_img].join(' ')}></div>
                          )}
                        </div>
                      </div>
                      <div className={styles.tbl_data}>
                        <div className={[styles.txt, styles.pr_5].join(' ')}>
                          {courseItem?.title}
                        </div>
                      </div>
                      <div className={styles.tbl_data}>
                        {courseItem?.course_state?.toLowerCase() === 'draft' && (
                          <div className={[styles.txt, styles.pr_5].join(' ')}>
                            <div>
                            {courseItem?.launch_date
                                    ? IS_THAILAND ? formatDateInThailandTimezone(courseItem?.launch_date, 'DD/MM/YYYY') : dateIsoToTime(courseItem?.launch_date)
                                    : 'NA'}
                            </div>
                            {/* <div>
                                    {courseItem?.launch_date
                                      ? dateIsoToDate(courseItem?.launch_date)
                                      : ""}
                                  </div> */}
                          </div>
                        )}
                        {courseItem?.course_state?.toLowerCase() !== 'draft' && (
                          <div className={[styles.txt, styles.pr_5].join(' ')}>
                            <div>
                            {courseItem?.launch_date
                                    ? dateIsoToTime(courseItem?.launch_date)
                                    : ''}
                            </div>
                            <div>
                            {courseItem?.launch_date
                                    ? IS_THAILAND ? formatDateInThailandTimezone(courseItem?.launch_date, 'DD/MM/YYYY') : dateIsoToNormalDate(courseItem?.launch_date)
                                    : ''}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className={[
                          styles.tbl_data,
                          courseItem?.course_state === 'Live'
                            ? styles.live_card
                            : courseItem?.course_state === 'Offline'
                            ? styles.offline_card
                            : courseItem?.course_state === 'Draft'
                            ? styles.draft_card
                            : courseItem?.course_state === 'Archive'
                            ? styles.archive
                            : '',
                        ].join(' ')}
                      >
                        <div className={[styles.txt, styles.highlight].join(' ')}>
                          {courseItem?.course_state}
                        </div>
                      </div>
                    </div>
                  ))}
                  <ViewAllComponent
                    isLoading={isLoading}
                    showEmptyTemplateFor={''}
                    list={courseList}
                    SelectedNavigationOptionText={'COURSES-MANAGEMENT'}
                    pageName="Courses"
                    viewType="list"
                  />
                </>
              ) : isSearchFilterEmptyStateCourses && !isLoading ? (
                <EmptyScreenComponent />
              ) : (
                <LoadingShimmerList count={30} />
              )}
            </div>
            {/* View all */}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

CardAndListCourseView.propTypes = {};

export default CardAndListCourseView;
