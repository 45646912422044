import React, { useEffect, useState, useRef } from 'react';
// import styles from './DateRangePicker.module.scss';
import styles from './datePickerWithTimeSelection.module.scss';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import dayjs from 'dayjs';
import { setNotificationData } from '../../../redux/actions/uiActions';
import { useDispatch, useSelector } from 'react-redux';
import { th, enUS } from "react-day-picker/locale";
import { IS_THAILAND, getDayPickerFormatters } from '../../../utils/datePicker';
import { convertFullDateToUTC, formatDateInThailandTimezone, formatDateInUserTimezone } from '../../../utils/date';
import config from '../../../config';

const CURRENT_REGION = config?.currentRegion?.toLowerCase();

const DatePickerWithTimeSelection = (props) => {
    const { flexProperty, marginLeft, dateType = 'Status', date, setDateHanlder, disabled = false, label = '', startCourseDate = null } = props;
    const dispatch = useDispatch();

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs().startOf('day')?.$d);
    let DATE_FORMAT = useSelector(state => state?.uiState?.remoteConfig?.date_format || 'MM/DD/YYYY');
    DATE_FORMAT = IS_THAILAND ? 'MM/DD/YYYY' : DATE_FORMAT; 
    const [selectedDateString, setSelectedDateString] = useState(date?.formattedDate ? dayjs(date?.formattedDate).format(DATE_FORMAT) : dayjs(dayjs().startOf('day').$d).format(DATE_FORMAT));
    const [hour, setHour] = useState(date?.hour || '');
    const [minute, setMinute] = useState(date?.minute || '');
    const [period, setPeriod] = useState(date?.period || 'AM');
    const [month, setMonth] = useState(new Date());
    const [isApplyEnable, setIsApplyEnable] = useState(false);
    const currentDate = new Date();
    const minDate = dayjs(startCourseDate?.formattedDate, `${DATE_FORMAT} h:mm:ss A`).toDate()
    const maxDate = currentDate;

    const calendarRef = useRef(null); // Create a reference to the calendar container

    useEffect(() => {
        // Add event listener to close the calendar when clicking outside
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setIsCalendarOpen(false);
            }
        };

        // Attach the listener to the document
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (hour && minute && period) {
            setIsApplyEnable(true)
        }
    }, [selectedDate, hour, minute])

    useEffect(() => {
        if (date?.formattedDate) {
            const parsedDate = dayjs(date.formattedDate, `${DATE_FORMAT} hh:mm:ss A`).toDate();
            console.log('parsedDate: ', parsedDate);
            setSelectedDate(parsedDate);
        }

        if (date?.hour && date?.minute && date?.period) {
            setHour(date.hour);
            setMinute(date.minute);
            setPeriod(date.period || 'AM');
        }
    }, [])


    const resetValues = () => {
        if (date?.formattedDate) {
            const parsedDate = dayjs(date.formattedDate, `${DATE_FORMAT} hh:mm:ss A`).toDate();
            setSelectedDate(parsedDate);
        }
        if (date?.hour && date?.minute && date?.period) {
            setHour(date.hour);
            setMinute(date.minute);
            setPeriod(date.period || 'AM');
        }
        else {
            // setSelectedDate(dayjs().toISOString());
            setSelectedDate(dayjs().startOf('day')?.$d)
            setHour('');
            setMinute('');
            setIsApplyEnable(false)
        }

    }

    const handleMonthChange = (newMonth) => {
        setMonth(newMonth);
    };

    const handleCalendarClose = () => {
        if (disabled) {
            dispatch(setNotificationData({
                show: true,
                type: 'FAILURE',
                title: 'Disabled',
                description: `Please select course status first`,
            }))

            return;
        }
        setIsCalendarOpen(!isCalendarOpen)
        resetValues();
    };

    const handleDateSelect = (date) => {
        setSelectedDateString(dayjs(date).format(DATE_FORMAT));
        setSelectedDate(date);
    };

    // Update hour when period changes to enforce limits
    useEffect(() => {
        if (period === 'PM' && hour === '12') {
            setHour('11');
        }
        if (period === 'PM' && minute >= '00' && hour >= '11') {
            setMinute('00');
        }
    }, [period]);

    const handleHourChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Allow only digits

        // set max time to 23:00 or 11:00 PM
        // if (period == "PM") {
        //     if (parseInt(value) > 11) {
        //         value = 11;
        //         if ((minute) > '00') {
        //             setMinute(0)
        //         }
        //     }
        // }

        // If value is less than 1 or more than 12, enforce limits
        if (parseInt(value) > 12) {
            value = '12'; // Limit to 12
        } else if (parseInt(value) < 1 && value !== '0') {
            value = '01'; // Minimum value is 1
        }

        // If the value is a single digit and it's less than 10, keep it two digits (e.g., '1' becomes '01')
        if (value.length === 1 && value !== '0' && value !== '1') {
            value = '0' + value; // Ensure 2-digit format
        }

        setHour(value); // Set the formatted value
    };

    const handleMinuteChange = (e) => {

        let value = e.target.value.replace(/[^0-9]/g, ''); // Allow only digits

        // set max time to 23:00 or 11:00 PM
        // if (period == "PM" && parseInt(hour) >= 11) {
        //     if (parseInt(value) > 0) {
        //         value = '00';
        //     }
        // }

        if (parseInt(value) > 59) {
            value = '59'; // Limit to 59
        }
        setMinute(value);
    };

    const convertTo24Hour = (hour, minute, period) => {
        let hour24 = parseInt(hour, 10);
        if (period === 'AM' && hour24 === 12) {
            hour24 = 0; // 12 AM is midnight (00:00)
        } else if (period === 'PM' && hour24 !== 12) {
            hour24 += 12; // 12 PM is noon (12:00), but 1 PM to 11 PM should be 13 to 23
        }
        return { hour24, minute: parseInt(minute, 10) };
    };

    const handleApply = () => {
        if(!selectedDate) {

            dispatch(setNotificationData({
                show: true,
                type: 'FAILURE',
                title: 'Invalid Date Selection',
                description: `Please select a date first`,
            }));

            return;
        }

        if (dateType === 'Status') {
            const currentDate = new Date();
            const currentHour = currentDate.getHours() % 12 || 12; // Convert to 12-hour format
            const currentMinute = currentDate.getMinutes();
            const currentPeriod = currentDate.getHours() < 12 ? 'AM' : 'PM';

            const courseStartDateObj = dayjs(startCourseDate?.formattedDate, `${DATE_FORMAT} h:mm:ss A`);
            const selectedDateObj = dayjs(selectedDate);

            // Condition 1: If selectedDate is the same as courseStartDate
            if (courseStartDateObj.isSame(selectedDateObj, 'day')) {
                const courseStartHour = parseInt(startCourseDate?.hour, 10);
                const courseStartMinute = parseInt(startCourseDate?.minute, 10);
                const courseStartPeriod = startCourseDate?.period;

                if (courseStartPeriod !== period) {
                    if (courseStartPeriod === 'AM' && period === 'PM') {
                        // PM is always greater than AM, no issue
                    } else {
                        dispatch(setNotificationData({
                            show: true,
                            type: 'FAILURE',
                            title: 'Something went wrong',
                            description: `Status time must be greater than or equal to ${startCourseDate?.hour}:${startCourseDate?.minute} ${startCourseDate?.period}.`,
                        }));
                        let setDate = {
                            formattedDate: '',
                            hour: '',
                            minute: '',
                            period: ''
                        }
                        setDateHanlder(setDate)
                        return;
                    }
                } else if (
                    parseInt(hour, 10) < courseStartHour ||
                    (parseInt(hour, 10) === courseStartHour && parseInt(minute, 10) < courseStartMinute)
                ) {
                    dispatch(setNotificationData({
                        show: true,
                        type: 'FAILURE',
                        title: 'Something went wrong',
                        description: `Status time must be greater than or equal to ${startCourseDate?.hour}:${startCourseDate?.minute} ${startCourseDate?.period}.`,
                    }));
                    let setDate = {
                        formattedDate: '',
                        hour: '',
                        minute: '',
                        period: ''
                    }
                    setDateHanlder(setDate)
                    return;
                }
            }

            // Condition 2: If selectedDate is the current date
            if (dayjs(selectedDate).isSame(currentDate, 'day')) {
                // Convert current time and selected time to 24-hour format
                const { hour24: current24Hour, minute: current24Minute } = convertTo24Hour(currentHour, currentMinute, currentPeriod);
                const { hour24: selected24Hour, minute: selected24Minute } = convertTo24Hour(hour, minute, period);

                // Check if selected time is greater than current time
                if (
                    selected24Hour > current24Hour ||
                    (selected24Hour === current24Hour && selected24Minute > current24Minute)
                ) {
                    dispatch(setNotificationData({
                        show: true,
                        type: 'FAILURE',
                        title: 'Invalid Date Selection',
                        description: `Status time cannot be greater than the current time (${current24Hour}:${String(current24Minute).padStart(2, '0')} ${currentPeriod}).`,
                    }));

                    let setDate = {
                        formattedDate: '',
                        hour: '',
                        minute: '',
                        period: ''
                    };
                    setDateHanlder(setDate);
                    return;
                }
            }


        }

        const formattedTime = `${parseInt(hour)}:${minute.padStart(2, '0')}:00 ${period}`;
        const statusString = `${selectedDateString} ${formattedTime}`;

        const currentDateTime = dayjs();
        const selectedDateTimeParsed = dayjs(statusString, `${DATE_FORMAT} hh:mm:ss A`);

        // Compare if the selected date & time is greater than the current date & time
        if (selectedDateTimeParsed.isAfter(currentDateTime)) {
            dispatch(setNotificationData({
                show: true,
                type: 'FAILURE',
                title: 'Invalid Date Selection',
                description: `Date & time cannot be greater than the current date & time`,
            }));

            return;
        }

        let setDate = {
            formattedDate: statusString,
            hour: hour,
            minute: minute,
            period: period
        }

        setDateHanlder(setDate)
    };

    const getDateFieldValue = () => {
        if(date && date?.formattedDate) {
            if(CURRENT_REGION === 'th') {
                const formattedDate = formatDateInThailandTimezone(convertFullDateToUTC(date?.formattedDate), 'DD/MM/YYYY');
                const formattedTime = `${parseInt(hour)}:${minute.padStart(2, '0')}:00 ${period}`;

                return `${formattedDate} ${formattedTime}`;
            } else {
                return date?.formattedDate;
            }
        } else {
            return dateType + ' Date'
        }
    }

    console.log("DATE ", {date, format: convertFullDateToUTC(date?.formattedDate), full: formatDateInUserTimezone(convertFullDateToUTC(date?.formattedDate))})

    return (
        <div
            className={styles.dateRangeCalendarContainer}
            style={{ flex: flexProperty, marginLeft: marginLeft }}
            ref={calendarRef} // Attach ref to the container
        >
            {label && <label><b>{label}</b></label>}
            <div className={[styles.dateSelectorDropDownContainer, disabled ? styles.disabled : styles.active].join(' ')}>
                <div className={`${styles.selectedDate} ${!disabled ? styles.active : ''}`}>{getDateFieldValue()} </div>
                <div className={styles.icon_wrapper} onClick={handleCalendarClose}>
                    <div className={`${isCalendarOpen ? styles.upArrow : styles.downArrow} ${disabled ? styles.isDisabled : ''}`} />
                </div>
            </div>
            {isCalendarOpen && (
                <div className={styles.calendarContainer}>
                    <div className={styles.calendarHeader}>
                        <div className={styles.calendarHeaderText}>Select a {dateType} Date</div>
                        <div className={styles.closeIcon} onClick={handleCalendarClose}></div>
                    </div>
                    <div className={styles.lineDivider}></div>
                    <div className={styles.calendarDescription}>
                        Manually select the date and time the status change occurred.
                    </div>
                    <DayPicker
                        // locale={hi}
                        mode="single"
                        selected={selectedDate}
                        onSelect={handleDateSelect}
                        month={month}
                        onMonthChange={handleMonthChange}
                        captionLayout="dropdown-years"
                        classNames={{
                            root: `rdp-root ${styles.root}`,
                            dropdowns: `rdp-dropdowns ${styles.dropdowns}`,
                            months: `rdp-months ${styles.months}`,
                            button_previous: `rdp-button_previous ${styles.button_previous}`,
                            button_next: `rdp-button_next ${styles.button_next}`,
                            weekday: `rdp-weekday ${styles.weekday}`,
                        }}
                        modifiersClassNames={{
                            selected: `rdp-selected ${styles.selected}`,
                        }}
                        disabled={dateType === 'Status' ? {
                            before: minDate,
                            after: maxDate,
                        } : {
                            after: currentDate
                        }}
                        formatters={getDayPickerFormatters()}
                    />
                    <div className={styles.timeContainer}>
                        <div className={styles.timeTextContainer}>
                            <div className={styles.icon_wrapper} onClick={handleCalendarClose}>
                                <div className={styles.timeWatch} />
                            </div>
                            <div className={styles.timeText}>{dateType} Time</div>
                        </div>
                        <div className={styles.timeInputContainer}>
                            <input
                                type="text"
                                value={hour}
                                onChange={handleHourChange}
                                maxLength={2}
                                placeholder={hour ? hour : '08'}
                                className={styles.inputBoxes}
                            />
                            <span>:</span>
                            <input
                                type="text"
                                value={minute}
                                onChange={handleMinuteChange}
                                maxLength={2}
                                placeholder={minute ? minute : '00'}
                                className={styles.inputBoxes}
                            />
                            {/* <input
                                type="text"
                                value={period}
                                onKeyDown={handlePeriodChange}
                                readOnly
                                onWheel={handlePeriodScroll}
                                className={styles.inputBoxes}
                            /> */}
                            <select value={period ? period : 'AM'} onChange={(e) => setPeriod(e?.target?.value)} style={{ border: '2px solid #2C2C2C', borderRadius: '12px' }}>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.filter_button_wrapper}>
                        <button
                            type="button"
                            className={[styles.btn, styles.m_lr_6].join(' ')}
                            onClick={() => {
                                handleCalendarClose()
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className={[
                                styles.btn,
                                !isApplyEnable ? styles.btn_disable :
                                    styles.dark,
                                styles.m_lr_6,
                            ].join(' ')}
                            onClick={(e) => {
                                // formatDateRange(selected);
                                handleApply()
                                setIsCalendarOpen(!isCalendarOpen);
                            }}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DatePickerWithTimeSelection;
